import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'hooks';
import { OptionWithOldMarker } from 'types';
import themeColors from 'utils/theme-colors';
import { Textarea } from 'components/common/form/textarea';
import { Box } from 'components/common/box';
import config from 'config';
import { useTextPickerContext } from 'components/common/form/text-picker/hooks';
import { Typography } from 'components/common/typography';
import { AD_DESCRIPTION_FIELD, AD_TITLE_FIELD } from 'components/campaigns/campaign-form/constants';
import {
  GOOGLE_ADS_FIELD,
  GOOGLE_ADWORDS_FIELD,
  FACEBOOK_FIELD,
  INSTAGRAM_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector';
import { Icon } from 'components/common/icon';
import { minIndex } from './utils';
import { DESCRIPTIONS_FIELD, MAX_LENGTH, MAX_FB_LENGTH, MAX_FB_LENGTH_TITLE, MAX_LENGTH_TITLE } from './constants';
import { TextPickerRowProps } from './types';
import regenerate from './regenerate.svg';

const { textarea } = config.options;

export const TextPickerRow = memo<TextPickerRowProps>(
  ({
    rootFieldName,
    rowIndex,
    selectedOptionIndex,
    total,
    controls,
    borderColor = themeColors.grey13,
    onRemove: onRemoveFromProps,
    options,
    setOptions,
    selectedOption,
    selectOption,
    onRegenerateDescriptionsClick,
    onRegenerateTitlesClick,
    valueLength,
  }) => {
    const { t } = useTranslation();
    const { tabName, placeholder } = useTextPickerContext();

    const [value, setValue] = useState('');

    const namePrefix = `${rootFieldName}[${rowIndex}]`;
    const selectFieldName = `${namePrefix}.label`;

    const items = useMemo(
      () => [
        t('AdTextPicker.Items.First'),
        t('AdTextPicker.Items.Second'),
        t('AdTextPicker.Items.Third'),
        t('AdTextPicker.Items.Fourth'),
        t('AdTextPicker.Items.Fifth'),
        t('AdTextPicker.Items.Sixth'),
        t('AdTextPicker.Items.Seventh'),
        t('AdTextPicker.Items.Eighth'),
        t('AdTextPicker.Items.Ninth'),
        t('AdTextPicker.Items.Tenth'),
        t('AdTextPicker.Items.Eleventh'),
        t('AdTextPicker.Items.Twelfth'),
        t('AdTextPicker.Items.Thirteenth'),
        t('AdTextPicker.Items.Fourteenth'),
        t('AdTextPicker.Items.Fifteenth'),
      ],
      [t],
    );

    const showControls = true;

    const ordinalText = useCallback(
      (index: number) => {
        if (items[index]) {
          return items[index];
        }
        return rootFieldName === AD_TITLE_FIELD ? t('AdTextPicker.Labels.Title') : t('AdTextPicker.Labels.Description');
      },
      [items, rootFieldName, t],
    );

    const size = useMemo(
      () =>
        rootFieldName === AD_TITLE_FIELD
          ? {
              minRows: textarea.rowsMin,
              maxRows: textarea.rowsMin,
            }
          : {
              minRows: textarea.rows,
              maxRows: textarea.rows,
            },
      [rootFieldName],
    );

    const isEnabledRegenerateDescriptions = useMemo(() => rootFieldName === AD_DESCRIPTION_FIELD, [rootFieldName]);
    const isEnabledRegenerateTitles = useMemo(() => rootFieldName === AD_TITLE_FIELD, [rootFieldName]);

    const onRemove = useCallback(() => {
      onRemoveFromProps(rowIndex);
    }, [onRemoveFromProps, rowIndex]);

    const onRegenerate = useCallback(() => {
      onRegenerateDescriptionsClick(
        rowIndex,
        selectedOption(rowIndex)?.use_case_group,
        selectedOption(rowIndex)?.asset_number,
      );
    }, [onRegenerateDescriptionsClick, rowIndex, selectedOption]);

    const onRegenerateTitle = useCallback(() => {
      onRegenerateTitlesClick(
        rowIndex,
        selectedOption(rowIndex)?.use_case_group,
        selectedOption(rowIndex)?.asset_number,
      );
    }, [onRegenerateTitlesClick, rowIndex, selectedOption]);

    const showButton = rowIndex >= minIndex(rootFieldName, tabName);

    const onChange = useCallback(
      (e: any) => {
        const newOptions = options.map((option: OptionWithOldMarker) => ({
          ...option,
          label: option.label,
        }));

        selectOption(
          {
            value: undefined,
            label: e.target.value,
          },
          rowIndex,
        );

        setOptions(newOptions);

        setValue(e.target.value);
      },
      [options, rowIndex, selectOption, setOptions],
    );

    const showCount = useMemo(
      () =>
        (tabName === GOOGLE_ADS_FIELD ||
          tabName === GOOGLE_ADWORDS_FIELD ||
          tabName === FACEBOOK_FIELD ||
          tabName === 'variantB' ||
          tabName === INSTAGRAM_FIELD) &&
        rootFieldName === DESCRIPTIONS_FIELD,
      [rootFieldName, tabName],
    );
    return (
      <Box display='flex' alignItems='start' position='relative'>
        {showButton ? (
          <Box display='flex' position='absolute' right='97.5%' top='30%' marginRight={20}>
            <Icon name='minus' size={20} circle color='primaryColor' cursor='pointer' onClick={onRemove} />
          </Box>
        ) : null}
        <Box display='flex'>
          <Box display='flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Textarea
              name={selectFieldName}
              placeholder={t(placeholder, {
                current: ordinalText(rowIndex),
              })}
              style={{
                border: `1px solid ${borderColor}`,
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 25,
                paddingRight: 80,
                minWidth: '400px',
                minHeight: '80px',
                maxHeight: '300px',
                lineHeight: 'initial',
                resize: 'none',
              }}
              autoSize={size}
              value={{ ...selectedOption(rowIndex) }.label}
              onChange={onChange}
              maxLength={
                showCount
                  ? tabName === FACEBOOK_FIELD || tabName === INSTAGRAM_FIELD
                    ? MAX_FB_LENGTH
                    : MAX_LENGTH
                  : undefined
              }
            />
            {isEnabledRegenerateDescriptions && showControls ? (
              <Box
                style={{
                  marginLeft: '-35px',
                  marginRight: '15px',
                  zIndex: '1',
                }}
              >
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  type='button'
                  onClick={onRegenerate}
                >
                  <img src={regenerate} alt='' />
                </button>
              </Box>
            ) : null}
            {isEnabledRegenerateTitles ? (
              <Box
                style={{
                  marginLeft: '-35px',
                  marginRight: '15px',
                  zIndex: '1',
                }}
              >
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  type='button'
                  onClick={onRegenerateTitle}
                >
                  <img src={regenerate} alt='' />
                </button>
              </Box>
            ) : null}
            {showCount ? (
              <Typography
                color='greyTransparent2'
                variant='body-18'
                style={{
                  whiteSpace: 'nowrap',
                  marginRight: '-110px',
                  position: 'absolute',
                  bottom: '5px',
                }}
              >
                {`${valueLength[rowIndex].label?.length || selectedOption(rowIndex)?.label?.length || 0} / ${
                  tabName === FACEBOOK_FIELD || tabName === INSTAGRAM_FIELD || tabName === 'variantB'
                    ? MAX_FB_LENGTH
                    : MAX_LENGTH
                }`}
              </Typography>
            ) : (
              <Typography
                color='greyTransparent2'
                variant='body-18'
                style={{
                  whiteSpace: 'nowrap',
                  marginRight: '-60px',
                  position: 'absolute',
                  bottom: '5px',
                }}
              >
                {`${valueLength[rowIndex].label?.length || selectedOption(rowIndex)?.label?.length || 0} / ${
                  tabName === FACEBOOK_FIELD || tabName === INSTAGRAM_FIELD || tabName === 'variantB'
                    ? MAX_FB_LENGTH_TITLE
                    : MAX_LENGTH_TITLE
                }`}
              </Typography>
            )}
            {showControls ? (
              <Box display='flex' style={{ marginLeft: '10px' }}>
                {controls(rowIndex)}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  },
);
