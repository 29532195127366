import { cast, getRoot, toGenerator, types, applySnapshot, SnapshotOut, Instance } from 'mobx-state-tree';
import pick from 'lodash.pick';
import omit from 'lodash.omit';
import get from 'lodash.get';
import { apiFlow, nullable } from 'stores/mst-types';
import Base from 'stores/models/base';
import Campaign, { CampaignInstanceType, CampaignFormValues } from 'stores/models/campaigns/campaign';
import CampaignSocialNetworks from 'stores/models/campaigns/campaign-social-networks';
import CampaignTextSuggestions from 'stores/models/campaigns/campaign-text-suggestions';
import CampaignTarget, {
  CampaignTargetInstanceType,
  CampaignTargetSnapshot,
} from 'stores/models/campaigns/campaign-target';
import Option from 'stores/models/option';
import KeywordGroup, { KeywordGroupSnapshot } from 'stores/models/keyword-group';
import { ImageWithOldMarkerSnapshot } from 'stores/models/image-with-old-marker';
import { fromItemResponse } from 'services/api';
import config from 'config';
import { History } from 'history';
import { locations } from 'navigation';
import logo from 'assets/images/logo_1200_300.png';
import { isEmpty } from 'utils/is';
import { base64ToFile } from 'utils/files';
import { toJSONDeep } from 'utils/stores';
import { excludeEmpty, mergeDeep, set } from 'utils/objects';
import { toDateRangeFromValue, toDateStartFromValue } from 'utils/forms';
import { SocialNetworksAvailability } from 'types';
import {
  FACEBOOK_IS_ACTIVE_FIELD,
  GOOGLE_ADS_IS_ACTIVE_FIELD,
  GOOGLE_ADWORDS_IS_ACTIVE_FIELD,
  INSTAGRAM_IS_ACTIVE_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector';
import { KeywordSnapshot } from 'stores/models/keyword';
import ABTestingOverview from 'stores/models/ab-testing/ab-testing';
import variantAbTesting from 'stores/models/variant-ab-testing';
import googleSearch from 'stores/models/campaigns/google-search';
import OptionEntity from 'stores/models/option-entity';

const { statuses, types: campaignTypes } = config.api.constants.campaigns;
const { brandingCampaignTargetKinds } = config.api.constants;
const { campaignImages } = config.options;

const getCampaignLocationByType = (
  type: CampaignInstanceType['type']['id'],
): {
  toUrl: (data: { id: string }) => string;
} => {
  switch (type) {
    case campaignTypes.building.id:
      return locations.campaigns.buildingCampaign;
    case campaignTypes.listing.id:
      return locations.campaigns.listingCampaign;
    case campaignTypes.openHouse.id:
      return locations.campaigns.openHouseCampaign;
    case campaignTypes.branding.id:
      return locations.campaigns.brandingCampaign;
    default:
      throw new Error('location is not found');
  }
};

type BuildingCampaignTargetLoadOptions = Pick<CampaignTargetInstanceType, 'id'>;
type ListingCampaignTargetLoadOptions = Pick<CampaignTargetInstanceType, 'id'>;
type OpenHouseCampaignTargetLoadOptions = Pick<CampaignTargetInstanceType, 'id'> & {
  targetKindId: NonNullable<CampaignInstanceType['targetKind']>['id'];
};
type BrandingCampaignTargetLoadOptions = {
  targetKindId: NonNullable<CampaignInstanceType['targetKind']>['id'];
};

type CampaignTargetLoadOptions =
  | BuildingCampaignTargetLoadOptions
  | ListingCampaignTargetLoadOptions
  | OpenHouseCampaignTargetLoadOptions
  | BrandingCampaignTargetLoadOptions;

type TextSuggestionsLoadOptions = CampaignTargetLoadOptions;

export const CampaignManagerStore = Base.named('CampaignManagerStore')
  .props({
    googleSearch: nullable(googleSearch),
    campaign: nullable(Campaign),
    campaignKindId: nullable(Campaign.properties.type.properties.id),
    targetKind: nullable(Campaign.properties.type.properties.id),
    target: nullable(CampaignTarget),
    socialNetworks: types.optional(CampaignSocialNetworks, {}),
    textSuggestions: types.optional(CampaignTextSuggestions, {}),
    keywordOptions: types.optional(types.array(KeywordGroup), []),
    notFoundUpdateFallbackUrl: nullable(types.string),
    rawBuildingOptions: types.optional(types.array(OptionEntity), []),
    rawListingOptions: types.optional(types.array(OptionEntity), []),
    isInitialization: types.optional(types.boolean, true),
    socialNetworksAvailability: types.optional(
      types.model({
        facebook: types.optional(types.boolean, false),
        instagram: types.optional(types.boolean, false),
        googleAds: types.optional(types.boolean, false),
        googleAdwords: types.optional(types.boolean, false),
      }),
      {},
    ),
    abTestingOverview: types.optional(ABTestingOverview, {}),
    isABTesting: types.optional(types.boolean, false),
    showABToggle: types.optional(types.boolean, true),
    variantAbTesting: types.optional(variantAbTesting, {}),
  })
  .views((self) => ({
    get defaultAdValues() {
      return {
        ...toJSONDeep({
          titles: self.textSuggestions.defaultTitle,
          descriptions: self.textSuggestions.defaultDescription,
          images: this.adImagesNew,
        }),
        logo: this.defaultLogo,
      };
    },

    get defaultFacebookDescriptions() {
      return self.textSuggestions.defaultFacebookDescription.length
        ? self.textSuggestions.defaultFacebookDescription.slice(0, 1)
        : [{}];
    },

    get defaultFacebookTitles() {
      return self.textSuggestions.defaultFacebookTitle.length
        ? self.textSuggestions.defaultFacebookTitle.slice(0, 1)
        : [{}];
    },

    get defaultGoogleAdsTitles() {
      return self.textSuggestions.defaultGoogleTitle?.length
        ? self.textSuggestions.defaultGoogleTitle.slice(0, 5)
        : [{}];
    },

    get defaultGoogleAdsDescriptions() {
      return self.textSuggestions.defaultGoogleDescription?.length
        ? self.textSuggestions.defaultGoogleDescription.slice(0, 5)
        : [{}];
    },

    get defaultGoogleAdWordsTitles() {
      return self.textSuggestions.defaultGoogleTitle?.length
        ? self.textSuggestions.defaultGoogleTitle.slice(0, 10)
        : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    },

    get defaultGoogleAdWordsDescriptions() {
      return self.textSuggestions.defaultGoogleDescription?.length
        ? self.textSuggestions.defaultGoogleDescription.slice(0, 4)
        : [{}, {}, {}, {}];
    },

    get defaultFacebookAdValues() {
      return {
        ...this.defaultAdValues,
        titles:
          self.campaign?.facebookAd?.titles?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.facebookAd?.titles
            : this.defaultFacebookTitles,
        descriptions:
          self.campaign?.facebookAd?.descriptions?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.facebookAd?.descriptions
            : this.defaultFacebookDescriptions,
      };
    },

    get defaultGoogleAdsValues() {
      return {
        ...this.defaultAdValues,
        titles:
          self.campaign?.googleAdsAd?.titles?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.googleAdsAd?.titles
            : this.defaultGoogleAdsTitles,
        descriptions:
          self.campaign?.googleAdsAd?.descriptions?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.googleAdsAd?.descriptions
            : this.defaultGoogleAdsDescriptions,
      };
    },

    get defaultKeywords() {
      return self.keywordOptions.map((option) => ({
        group: option.group,
        keywords: option.keywords.filter(({ isOld }) => !isOld),
      }));
    },

    get mergeKeywordOptions() {
      return self.campaign?.googleAdWordsAd?.keywords?.map((option) => ({
        group: option.group,
        keywords: option.keywords?.concat(
          this.defaultKeywords
            .find((keyword) => keyword.group.value === option.group.value)
            ?.keywords.filter(
              (key) => !option.keywords?.find((optionKeyword) => optionKeyword.value === key.value),
            ) as KeywordSnapshot[],
        ),
      }));
    },

    get campaignKeywords() {
      if (self.campaign?.isKeywordsUpdated) {
        return this.mergeKeywordOptions;
      }
      return self.campaign?.googleAdWordsAd?.keywords as KeywordGroupSnapshot[];
    },

    get optionalCampaignKeywords() {
      if (self.campaign && self.campaign?.id) {
        return this.defaultKeywords
          .filter(
            ({ group }) =>
              !this.campaignKeywords?.find(({ group: campaignGroup }) => campaignGroup.value === group.value),
          )
          .concat(this.mergeKeywordOptions as any);
      }
      return this.defaultKeywords;
    },

    get defaultGoogleAdWordsValues() {
      return {
        ...this.defaultAdValues,
        titles:
          self.campaign?.googleAdWordsAd?.titles?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.googleAdWordsAd?.titles
            : this.defaultGoogleAdWordsTitles,
        descriptions:
          self.campaign?.googleAdWordsAd?.descriptions?.length && self.target?.id === self.campaign?.targetId
            ? self.campaign?.googleAdWordsAd?.descriptions
            : this.defaultGoogleAdWordsDescriptions,
        keywords: self.campaign && self.campaign.id ? this.defaultKeywords : this.defaultKeywords,
      };
    },

    get defaultLogo() {
      return self.target?.logo ? toJSONDeep(self.target.logo) : base64ToFile(logo);
    },

    get defaultAdsValue() {
      const { defaultAdValues, defaultFacebookAdValues, defaultGoogleAdsValues, defaultGoogleAdWordsValues } = this;
      const { images, logo } = defaultAdValues;
      const facebookAndInstagramImages = images?.slice(0, campaignImages.facebookAndInstagram.max);
      const googleAdsImages = images?.slice(0, campaignImages.googleAds.max);

      return {
        facebookAd: {
          titles: defaultFacebookAdValues.titles,
          descriptions: defaultFacebookAdValues.descriptions,
          images: facebookAndInstagramImages,
          name: 'facebookAd',
        },
        instagramAd: {
          titles: defaultFacebookAdValues.titles,
          descriptions: defaultFacebookAdValues.descriptions,
          images: facebookAndInstagramImages,
          name: 'instagramAd',
        },
        googleAdsAd: {
          titles: defaultGoogleAdsValues.titles,
          descriptions: defaultGoogleAdsValues.descriptions,
          images: googleAdsImages,
          logo,
          name: 'googleAdsAd',
        },
        googleAdWordsAd: {
          titles: defaultGoogleAdWordsValues.titles,
          descriptions: defaultGoogleAdWordsValues.descriptions,
          name: 'googleAdWordsAd',
          keywords: defaultGoogleAdWordsValues.keywords,
        },
      };
    },

    get adsValue() {
      if (self.campaign && self.campaign.targetId === self.target?.id) {
        const { facebook, instagram, googleAds, googleAdwords } = self.socialNetworksAvailability;

        const campaignSocialNetworks = toJSONDeep(
          mergeDeep(pick(self.campaign, ['facebookAd', 'instagramAd', 'googleAdsAd', 'googleAdWordsAd']), {
            facebookAd: pick(this.defaultFacebookAdValues, 'descriptions', 'titles'),
            instagramAd: pick(this.defaultFacebookAdValues, 'descriptions', 'titles'),
            googleAdsAd: pick(this.defaultGoogleAdsValues, 'descriptions', 'titles'),
            googleAdWordsAd: pick(this.defaultGoogleAdWordsValues, 'descriptions', 'titles', 'keywords'),
          }),
        );

        return campaignSocialNetworks
          ? set(campaignSocialNetworks, {
              [FACEBOOK_IS_ACTIVE_FIELD]: facebook && get(self.campaign, FACEBOOK_IS_ACTIVE_FIELD),
              [INSTAGRAM_IS_ACTIVE_FIELD]: instagram && get(self.campaign, INSTAGRAM_IS_ACTIVE_FIELD),
              [GOOGLE_ADWORDS_IS_ACTIVE_FIELD]:
                googleAds && !this.isGoogleTokenExpired && get(self.campaign, GOOGLE_ADWORDS_IS_ACTIVE_FIELD),
              [GOOGLE_ADS_IS_ACTIVE_FIELD]:
                googleAdwords && !this.isGoogleTokenExpired && get(self.campaign, GOOGLE_ADS_IS_ACTIVE_FIELD),
            } as any)
          : this.defaultAdsValue;
      }

      return this.defaultAdsValue;
    },

    get campaignValues() {
      return self.campaign
        ? excludeEmpty({
            ...pick(self.campaign, [
              'name',
              'fbDailyBudget',
              'adsDailyBudget',
              'adwordsDailyBudget',
              'targetId',
              'fbPageId',
              'instagramAccountId',
              'fbPixelId',
              'fbCustomAudienceId',
              'googleClientId',
              'isABTesting',
            ]),
            targetKind: self.campaign?.targetKind?.id,
            runDates: toDateRangeFromValue(self.campaign.runDates),
            runDate: toDateStartFromValue(self.campaign.runDate),
            geoTargeting: self.campaign ? toJSONDeep(self.campaign?.geoTargeting) : undefined,
            status: self.campaign.status.id,
          })
        : {};
    },

    get targetInitializationValues() {
      return {
        ...this.adsValue,
        targetId: self.target?.id,
        typeOfDatePicker: self.campaign?.typeOfDatePicker ?? false,
      };
    },

    get adImagesNew() {
      return this.imageOptions?.filter(({ isOld }) => !isOld);
    },

    get imageOptions() {
      return [...(self.target?.images || [])];
    },

    get approvedImages() {
      return self.target?.approvedImages;
    },

    get adTitlesNew() {
      return this.imageOptions?.filter(({ isOld }) => !isOld);
    },

    get titleOptions() {
      return [...(self.target?.images || [])];
    },

    get buildingOptions() {
      return [...self.rawBuildingOptions];
    },

    get listingOptions() {
      return [...self.rawListingOptions];
    },

    // TODO move to branding store
    get sessionUser() {
      const { user } = getRoot<any>(self).session;

      return user;
    },

    // TODO move to branding store
    get sessionUserOrganizationId() {
      return this.sessionUser?.organizationId;
    },

    get isSomeGoogleSocialNetworkActive() {
      return self.campaign?.googleAdsAd?.isActive || self.campaign?.googleAdWordsAd?.isActive;
    },

    get isGoogleAccountConnected() {
      const { session } = getRoot<any>(self);
      const { user, isSelectedUser, selectedUser } = session;

      if (isSelectedUser) {
        return selectedUser?.google?.isConnected;
      }
      return user?.google?.isConnected;
    },

    get isFacebookAccountConnected() {
      const { session } = getRoot<any>(self);
      const { user, isSelectedUser, selectedUser } = session;

      if (isSelectedUser) {
        return selectedUser?.facebook?.isConnected;
      }
      return user?.facebook?.isConnected;
    },

    get isGoogleTokenExpired() {
      return this.isSomeGoogleSocialNetworkActive && !this.isGoogleAccountConnected;
    },
  }))
  .actions((self) => ({
    setTarget(target: CampaignTargetSnapshot): void {
      self.target = target;
    },

    setTargetKind(targetKind: string): void {
      self.targetKind = targetKind;
    },
    setABToggle(show: boolean): void {
      self.showABToggle = show;
    },

    setVariant(value: any) {
      self.variantAbTesting = value;
    },

    updateCampaignInfo(value: any) {
      self.campaign = null;
      self.campaign = cast(value);
    },

    // TODO move to branding store
    loadOrganizationAsTarget: apiFlow(function* loadOrganizationAsTarget() {
      if (!self.sessionUserOrganizationId) {
        return;
      }

      const response = yield* toGenerator(self.api.getOrganization({ id: self.sessionUserOrganizationId }));

      const { data } = fromItemResponse({
        response: response.data,
      });

      const { getGeoTarget } = getRoot<any>(self).common;

      const location = yield* toGenerator(
        getGeoTarget({
          stateId: data?.state,
          cityId: data?.city,
        }),
      );

      self.target = cast(
        CampaignTarget.fromResponseData({
          id: data.id,
          name: data.name,
          url: data.website,
          logo: data.logo,
          has_affected_campaigns: data.has_affected_campaigns,
          images: data.organization_photos,
          location: `${location?.city.label}, ${location?.state.abbr}`,
        }),
      );
    }),
    // TODO move to branding store
    loadUserAsTarget: apiFlow(function* loadUserAsTarget() {
      const { sessionUser } = self;

      if (!sessionUser) {
        return;
      }

      const { getGeoTarget } = getRoot<any>(self).common;

      const location = yield* toGenerator(
        getGeoTarget({
          stateId: sessionUser.state,
          cityId: sessionUser.city,
        }),
      );

      self.target = cast(
        CampaignTarget.fromResponseData({
          id: sessionUser.id,
          name: sessionUser.fullName,
          url: sessionUser.website,
          images: sessionUser.featuredPhotos.map(({ isOld, ...image }: ImageWithOldMarkerSnapshot) => ({
            ...image,
            is_old: isOld,
          })),
          // cast hasAffectedCampaigns boolean from model to expected api response
          has_affected_campaigns: sessionUser.hasAffectedCampaigns ? [0] : [],
          location: `${location?.city.label}, ${location?.state.abbr}`,
        }),
      );

      yield Promise.resolve();
    }),

    loadKeywordOptions: apiFlow(function* loadKeywordOptions({
      targetKindId,
      targetId,
    }: {
      targetKindId?: NonNullable<CampaignInstanceType['targetKind']>['id'];
      targetId?: CampaignInstanceType['targetId'];
    }) {
      if (!targetId) {
        return;
      }

      const { getKeywordOptions } = getRoot<any>(self).common;

      self.keywordOptions = yield getKeywordOptions({
        campaign_id: self.campaign?.id,
        campaign_kind: self.campaignKindId,
        target_kind: targetKindId,
        target_id: targetId,
      });
    }),

    loadTargetLocation: apiFlow(function* loadTargetLocation(stateId, cityId) {
      const { getGeoTarget } = getRoot<any>(self).common;

      const location = yield* toGenerator(
        getGeoTarget({
          stateId,
          cityId,
        }),
      );

      if (self.target) {
        self.target.location = `${location?.city.label}, ${location?.state.abbr}`;
      }
    }),
  }))
  .actions((self) => ({
    // TODO move loading model to appropriate stores
    loadBuildingTarget: apiFlow(function* loadBuildingTarget({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const response = yield* toGenerator(self.api.getBuilding({ id }));
      const { data } = fromItemResponse({
        response: response.data,
      });
      const { setBuildingTargetId } = getRoot<any>(self).googleSearchAd;
      setBuildingTargetId(data.id);

      const { setABToggle, setBuildingId } = getRoot<any>(self).abTestingCampaign;

      setBuildingId(id);
      setABToggle(true);
      self.setTarget(CampaignTarget.fromResponseData(data));

      self.loadTargetLocation(data.state, data.city);
    }),

    loadGoogleSearch: apiFlow(function* loadGoogleSearch({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const response = yield* toGenerator(self.api.getAdGroups({ id }));
      const { data } = fromItemResponse({
        response: response.data,
      });

      const { setAdGroupsForGoogleSearch, setGoogleSearchTitles, setGoogleSearchDescriptions, setBuildingGroups } =
        getRoot<any>(self).googleSearchAd;
      setBuildingGroups(data.ad_groups);
      if (self.target?.id !== self.campaign?.targetId) {
        setAdGroupsForGoogleSearch(data.ad_groups);
        setGoogleSearchTitles(data.generated_titles);
        setGoogleSearchDescriptions(data.generated_descriptions);
      } else if (
        self.campaign?.status.id === '9' &&
        self.target?.id === self.campaign?.targetId &&
        !self.campaign?.googleAdWordsAd?.isActive
      ) {
        setAdGroupsForGoogleSearch(data.ad_groups);
        setGoogleSearchTitles(data.generated_titles);
        setGoogleSearchDescriptions(data.generated_descriptions);
      }
    }),

    loadListingTarget: apiFlow(function* loadListingTarget({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const response = yield* toGenerator(self.api.getListing({ id }));
      const { data } = fromItemResponse({
        response: response.data,
      });

      self.setTarget(CampaignTarget.fromResponseData(data));
      const { setABToggle } = getRoot<any>(self).abTestingCampaign;
      setABToggle(false);

      self.loadTargetLocation(data.state, data.city);
    }),

    create: apiFlow(
      function* create(values: Partial<CampaignFormValues>) {
        if (!self.campaignKindId) {
          return;
        }
        if (values.googleAdWordsAd?.isActive) {
          const { adGroups } = getRoot<any>(self).googleSearchAd;
          const adGroupTitles = adGroups[0].titles;
          const adGroupDescriptions = adGroups[0].descriptions;
          const adGroupKeywords = adGroups.map((e: any) => ({
            group: e.group,
            keywords: e.keywords,
          }));

          const mutableKey = 'googleAdWordsAd';
          const newValue: any = {
            name: values.googleAdWordsAd?.name,
            isActive: values.googleAdWordsAd?.isActive,
            keywords: adGroupKeywords,
            titles: adGroupTitles.slice(0, 15),
            descriptions: adGroupDescriptions,
          };

          const newValues: Partial<CampaignFormValues> = {
            ...values,
            [mutableKey]: newValue,
          };

          const response = yield* toGenerator(
            self.api.postCampaign(
              Campaign.toRequestData({
                type: self.campaignKindId,
                ...newValues,
              }),
            ),
          );
          const { data } = fromItemResponse({
            response: response.data,
          });

          const newAdGroups = adGroups.map((adGroup: any) => ({
            descriptions: adGroup.descriptions.slice(0, 4),
            titles: adGroup.titles.slice(0, 15),
            group: adGroup.group,
            keywords: adGroup.keywords,
            type: adGroup.type,
            id: adGroup.id,
          }));
          const statusMapper = (status: string) => {
            switch (status) {
              case 'status-1':
                return 'Published';
              case 'status-2':
                return 'Finish';
              case 'status-3':
                return 'Created';
              case '5':
                return 'Paused';
              case '8':
                return 'Live';
              case '9':
                return 'Draft';
              case '4':
                return 'Closed';
              default:
                return 'No status';
            }
          };

          const bodyGoogleSearch = {
            status: statusMapper(data.status.toString()),
            ad_groups: newAdGroups,
          };

          if (data.google_adwords_ad.is_active) {
            yield* toGenerator(
              self.api.postAdGroups({
                id: data.id,
                data: bodyGoogleSearch,
              }),
            );
          }
          self.campaign = cast(Campaign.fromResponseData(data));
        } else {
          const response = yield* toGenerator(
            self.api.postCampaign(
              Campaign.toRequestData({
                type: self.campaignKindId,
                ...values,
              }),
            ),
          );
          const { data } = fromItemResponse({
            response: response.data,
          });

          self.campaign = cast(Campaign.fromResponseData(data));
        }
      },
      { handleNotFound: true, formName: 'campaign' },
    ),

    update: apiFlow(
      function* update(values: Partial<CampaignFormValues>) {
        const id = self.campaign?.id;

        if (isEmpty(id) || !self.campaignKindId) {
          return;
        }

        if (values.googleAdWordsAd?.isActive && values.status !== '5' && self.campaign?.status.id !== '5') {
          const { adGroups } = getRoot<any>(self).googleSearchAd;
          const adGroupTitles = adGroups[0].titles;
          const adGroupDescriptions = adGroups[0].descriptions;
          const adGroupKeywords = self.campaign?.googleAdWordsAd?.keywords;

          const mutableKey = 'googleAdWordsAd';
          const newValue: any = {
            name: values.googleAdWordsAd?.name,
            isActive: values.googleAdWordsAd?.isActive,
            keywords: adGroupKeywords,
            titles: adGroupTitles.slice(0, 15),
            descriptions: adGroupDescriptions,
          };

          const newValues: Partial<CampaignFormValues> = {
            ...values,
            [mutableKey]: newValue,
          };
          const response = yield* toGenerator(
            self.api.patchCampaign({
              // @ts-ignore
              id,
              data: Campaign.toRequestData({
                type: self.campaignKindId,
                ...newValues,
              }),
            }),
          );

          const { data } = fromItemResponse({
            response: response.data,
          });

          if (self.campaign) {
            applySnapshot(self, {
              ...self,
              campaign: Campaign.create(Campaign.fromResponseData(data)),
            });
          }

          const { isABTesting, statusAB, setDisableWhileAB } = getRoot<any>(self).abTestingCampaign;
          if (isABTesting && statusAB !== 'RUNNING') {
            const newData = {
              variant_b: values.variantAbTesting,
            };
            if (self.campaign?.id && self.campaign?.status.id !== '9') {
              yield* toGenerator(
                self.api.patchABTesting({
                  id: self.campaign?.id,
                  data: newData,
                }),
              );
            }
            setDisableWhileAB(true);
          }

          const newAdGroups = adGroups.map((adGroup: any) => ({
            descriptions: adGroup.descriptions.slice(0, 4),
            titles: adGroup.titles.slice(0, 15),
            group: adGroup.group,
            keywords: adGroup.keywords,
            type: adGroup.type,
            id: adGroup.id,
          }));

          const statusMapper = (status: string) => {
            switch (status) {
              case 'status-1':
                return 'Published';
              case 'status-2':
                return 'Finish';
              case 'status-3':
                return 'Created';
              case '5':
                return 'Paused';
              case '8':
                return 'Live';
              case '9':
                return 'Draft';
              case '4':
                return 'Closed';
              default:
                return 'No status';
            }
          };

          const bodyGoogleSearch = {
            status: statusMapper(data.status.toString()),
            ad_groups: newAdGroups,
          };

          if (data.google_adwords_ad.is_active) {
            yield* toGenerator(
              self.api.postAdGroups({
                id: data.id,
                data: bodyGoogleSearch,
              }),
            );
          }
        } else {
          const response = yield* toGenerator(
            self.api.patchCampaign({
              // @ts-ignore
              id,
              data: Campaign.toRequestData({
                type: self.campaignKindId,
                ...values,
              }),
            }),
          );

          const { data } = fromItemResponse({
            response: response.data,
          });

          if (self.campaign) {
            applySnapshot(self, {
              ...self,
              campaign: Campaign.create(Campaign.fromResponseData(data)),
            });
          }

          const { isABTesting, statusAB, setDisableWhileAB } = getRoot<any>(self).abTestingCampaign;
          if (isABTesting && statusAB !== 'RUNNING') {
            const newData = {
              variant_b: values.variantAbTesting,
            };
            if (self.campaign?.id && self.campaign?.status.id !== '9') {
              yield* toGenerator(
                self.api.patchABTesting({
                  id: self.campaign?.id,
                  data: newData,
                }),
              );
            }
            setDisableWhileAB(true);
          }
        }
      },
      { handleNotFound: true, formName: 'campaign' },
    ),

    remove: apiFlow(
      function* remove() {
        const id = self.campaign?.id;

        if (isEmpty(id)) {
          return;
        }

        if (self.campaign?.googleAdWordsAd?.isActive) {
          // @ts-ignore
          yield self.api.deleteExistingAdGroupsCampaign({ id });
        }
        // @ts-ignore
        yield self.api.deleteCampaign({ id });

        setTimeout(() => {
          const history = self.services.get<History>('history');
          history.replace(locations.campaigns.list.toUrl());
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Removed',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),
  }))
  .actions((self) => ({
    loadCampaign: apiFlow(
      function* loadCampaign({ id }: Pick<CampaignInstanceType, 'id'>) {
        const response = yield* toGenerator(self.api.getCampaign({ id }));
        const { data } = fromItemResponse({
          response: response.data,
        });
        const { setIsBuildingUpdated, setCampaignTargetId } = getRoot<any>(self).googleSearchAd;
        setCampaignTargetId(data.target);

        setIsBuildingUpdated(data.is_keyword_updated);

        const ABStatus = yield* toGenerator(self.api.getABTestingStatus({ id }));

        const { data: abStatus } = fromItemResponse({
          response: ABStatus.data,
        });

        const dataFromResponse = Campaign.fromResponseData(data);
        self.updateCampaignInfo(dataFromResponse);
        const campaignKindId = self.campaign?.type.id;

        if (campaignKindId) {
          self.campaignKindId = campaignKindId;
        }

        if (self.campaign?.targetKind) {
          self.setTargetKind(self.campaign?.targetKind?.id);
        }

        if (self.campaign?.googleAdWordsAd?.isActive) {
          const result = yield* toGenerator(self.api.getExistingAdGroups({ id }));
          const { data: adGroupsResult } = fromItemResponse({
            response: result.data,
          });

          const { setAdGroupsForGoogleSearch, setGoogleSearchTitles, setGoogleSearchDescriptions } =
            getRoot<any>(self).googleSearchAd;
          if (self.campaign?.isKeywordsUpdated) {
            const resultBuildingUpdated = yield* toGenerator(self.api.getExistingAdGroupsBuildingUpdated({ id }));
            const { data: adGroupsResultBUildingUpdated } = fromItemResponse({
              response: resultBuildingUpdated.data,
            });
            setAdGroupsForGoogleSearch(adGroupsResultBUildingUpdated.ad_groups);
            setGoogleSearchTitles(adGroupsResultBUildingUpdated.generated_titles);
            setGoogleSearchDescriptions(adGroupsResultBUildingUpdated.generated_descriptions);
          } else {
            setAdGroupsForGoogleSearch(adGroupsResult.ad_groups);
            setGoogleSearchTitles(adGroupsResult.generated_titles);
            setGoogleSearchDescriptions(adGroupsResult.generated_descriptions);
          }
        }

        const {
          setIsABTesting,
          setStatusAB,
          setVariantA,
          setVariantB,
          setResultB,
          setResultA,
          setCampaignStatus,
          setDisableWhileAB,
        } = getRoot<any>(self).abTestingCampaign;
        setCampaignStatus(self.campaign?.status.id);

        if (abStatus.is_ab_testing) {
          setIsABTesting(abStatus.is_ab_testing);
          setVariantB(abStatus.ab_testing_details.variant_b);
          setVariantA(abStatus.ab_testing_details.variant_a);

          if (abStatus.ab_testing_details.status === 'RUNNING') {
            const resultAB = yield* toGenerator(self.api.getABTestingResult({ id }));
            const { data: resultABTesting } = fromItemResponse({
              response: resultAB.data,
            });
            setResultA(resultABTesting.variant_a);
            setResultB(resultABTesting.variant_b);
            setDisableWhileAB(true);
            setStatusAB(abStatus.ab_testing_details.status);
          } else {
            setDisableWhileAB(false);
          }
        }
      },
      { handleNotFound: true },
    ),

    loadTargetMain: apiFlow(function* loadTargetMain(options: CampaignTargetLoadOptions) {
      switch (self.campaignKindId) {
        case campaignTypes.building.id:
          if ('id' in options) {
            yield self.loadBuildingTarget({ id: options.id });
            yield self.loadGoogleSearch({ id: options.id });
          }
          return;
        case campaignTypes.listing.id:
          if ('id' in options) {
            yield self.loadListingTarget({ id: options.id });
          }
          return;
        case campaignTypes.openHouse.id:
          if ('id' in options && 'targetKindId' in options) {
            const { id, targetKindId } = options;

            if (targetKindId === campaignTypes.building.id) {
              yield self.loadBuildingTarget({ id });
            }
            if (targetKindId === campaignTypes.listing.id) {
              yield self.loadListingTarget({ id });
            }
          }
          return;
        case campaignTypes.branding.id:
          if ('targetKindId' in options) {
            const { targetKindId } = options;
            if (String(targetKindId) === brandingCampaignTargetKinds.agent.id) {
              yield self.loadUserAsTarget();
              return;
            }

            if (
              [brandingCampaignTargetKinds.brokerage.id, brandingCampaignTargetKinds.company.id].includes(
                String(targetKindId),
              )
            ) {
              yield self.loadOrganizationAsTarget();
            }
          }
          break;
        default:
          yield [] as any;
          break;
      }
    }),
    loadAdDescriptionRegenerateOptionsForGroup: apiFlow(function* loadAdDescriptionRegenerateOptionsForGroup(options: {
      id: string;
      platform: string;
      selectedGroup: any;
      use_case_group: string;
      asset_number: number;
    }) {
      return yield self.textSuggestions.loadBuildingDescriptionRegenerateForGroup({
        id: options.id,
        platform: options.platform,
        // @ts-ignore
        use_case_group: options.use_case_group,
        // @ts-ignore
        asset_number: options.asset_number,
        // @ts-ignore
        selectedGroup: options.selectedGroup,
      });
    }),
    loadAdTitleRegenerateOptionsForGroup: apiFlow(function* loadAdTitleRegenerateOptionsForGroup(options: {
      id: string;
      platform: string;
      selectedGroup: any;
      use_case_group: string;
      asset_number: number;
    }) {
      return yield self.textSuggestions.loadBuildingTitleRegenerateForGroup({
        id: options.id,
        platform: options.platform,
        // @ts-ignore
        use_case_group: options.use_case_group,
        // @ts-ignore
        asset_number: options.asset_number,
        // @ts-ignore
        selectedGroup: options.selectedGroup,
      });
    }),

    loadAdDescriptionRegenerateOptions: apiFlow(function* loadAdDescriptionRegenerateOptions(options: {
      id: string;
      platform: string;
      use_case_group: string;
      asset_number: number;
    }) {
      switch (self.campaignKindId) {
        case campaignTypes.building.id:
          if ('id' in options) {
            yield self.textSuggestions.loadBuildingDescriptionRegenerate({
              id: options.id,
              platform: options.platform,
              // @ts-ignore
              use_case_group: options.use_case_group,
              // @ts-ignore
              asset_number: options.asset_number,
            });
          }
          return;
        case campaignTypes.listing.id:
          if ('id' in options) {
            yield self.textSuggestions.loadListingDescriptionRegenerate({
              id: options.id,
              platform: options.platform,
              // @ts-ignore
              use_case_group: options.use_case_group,
              // @ts-ignore
              asset_number: options.asset_number,
            });
          }
          return;
        case campaignTypes.branding.id:
          if (self.targetKind) {
            yield self.textSuggestions.loadBrandingDescriptionRegenerate({
              typeId: self.targetKind,
              platform: options.platform,
            });
          }
          break;
        default:
          yield [] as any;
          break;
      }
    }),

    loadAdTitleRegenerateOptions: apiFlow(function* loadAdTitleRegenerateOptions(options: {
      id: string;
      platform: string;
      use_case_group: string;
      asset_number: number;
    }) {
      switch (self.campaignKindId) {
        case campaignTypes.building.id:
          if ('id' in options) {
            const result = yield self.textSuggestions.loadBuildingTitleRegenerate({
              id: options.id,
              platform: options.platform,
              // @ts-ignore
              use_case_group: options.use_case_group,
              // @ts-ignore
              asset_number: options.asset_number,
            });
            return result;
          }
          break;
        case campaignTypes.listing.id:
          if ('id' in options) {
            const result = yield self.textSuggestions.loadListingTitleRegenerate({
              id: options.id,
              platform: options.platform,
              // @ts-ignore
              use_case_group: options.use_case_group,
              // @ts-ignore
              asset_number: options.asset_number,
            });
            return result;
          }
          break;
        case campaignTypes.branding.id:
          if (self.targetKind) {
            yield self.textSuggestions.loadBrandingTitleRegenerate({
              typeId: self.targetKind,
              platform: options.platform,
            });
          }
          break;
        default:
          return [] as any;
      }
      return [] as any;
    }),

    loadAdSuggestionOptions: apiFlow(function* loadAdSuggestionOptions(options: TextSuggestionsLoadOptions) {
      switch (self.campaignKindId) {
        case campaignTypes.building.id:
          if ('id' in options) {
            yield self.textSuggestions.loadBuildingSuggestions({
              id: options.id,
            });
          }
          return;
        case campaignTypes.listing.id:
          if ('id' in options) {
            yield self.textSuggestions.loadListingSuggestions({
              id: options.id,
            });
          }
          return;
        case campaignTypes.openHouse.id:
          if ('id' in options && 'targetKindId' in options) {
            if (options.targetKindId === campaignTypes.building.id) {
              yield self.textSuggestions.loadBuildingSuggestions({
                id: options.id,
              });
            }
            if (options.targetKindId === campaignTypes.listing.id) {
              yield self.textSuggestions.loadListingSuggestions({
                id: options.id,
              });
            }
          }
          return;
        case campaignTypes.branding.id:
          if ('targetKindId' in options) {
            const { targetKindId } = options;
            if (String(targetKindId) === brandingCampaignTargetKinds.agent.id) {
              yield self.textSuggestions.loadBrandingUserSuggestions();
              return;
            }

            if (
              [brandingCampaignTargetKinds.brokerage.id, brandingCampaignTargetKinds.company.id].includes(
                String(targetKindId),
              )
            ) {
              yield self.textSuggestions.loadBrandingOrganizationSuggestions();
            }
          }
          break;
        default:
          yield [] as any;
          break;
      }
    }),

    loadBuildingOptions: apiFlow(function* loadBuildingOptions() {
      const { getBuildingOptions } = getRoot<any>(self).common;
      self.rawBuildingOptions = yield getBuildingOptions();
    }),

    loadListingOptions: apiFlow(function* loadListingOptions() {
      const { getListingOptions } = getRoot<any>(self).common;

      self.rawListingOptions = yield getListingOptions();
    }),

    resetTarget() {
      self.target = null;
      self.keywordOptions = cast([]);
      self.textSuggestions.destroy();

      if (self.campaign) {
        (self.campaign as CampaignInstanceType).resetTarget();
      }
    },

    newDraftSave: apiFlow(
      function* newDraftSave(data: Partial<CampaignFormValues>) {
        yield self.create({
          ...data,
          status: statuses.draft.id,
        });

        setTimeout(() => {
          const history = self.services.get<History>('history');
          history.push(locations.campaigns.list.toUrl());
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Created',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    existingDraftSave: apiFlow(
      function* existingDraftSave(data: Partial<CampaignFormValues>) {
        yield self.update({
          ...data,
          status: statuses.draft.id,
        });
        setTimeout(() => {
          const history = self.services.get<History>('history');
          history.push(locations.campaigns.list.toUrl());
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Updated',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    newGoLive: apiFlow(
      function* newGoLive({
        data,
        onSuccess,
      }: {
        data: Partial<CampaignFormValues>;
        onSuccess: (
          options: Pick<CampaignInstanceType, 'id'> & {
            showWarn?: boolean;
          },
        ) => void;
      }) {
        yield self.create({
          ...data,
          status: statuses.live.id,
        });

        const callError =
          self.campaign?.googleRecentError && self.campaign?.googleRecentError.callPhoneNumberNotSupportedForCountry;

        const { isABTesting } = getRoot<any>(self).abTestingCampaign;

        if (isABTesting) {
          const newData = {
            variant_b: data.variantAbTesting,
          };
          if (self.campaign?.id) {
            yield* toGenerator(
              self.api.patchABTesting({
                id: self.campaign?.id,
                data: newData,
              }),
            );
          }
        }
        setTimeout(() => {
          if (self.campaign) {
            onSuccess({
              id: self.campaign.id,
              showWarn: Boolean(callError),
            });
          }
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.GoLive',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    existingGoLive: apiFlow(
      function* existingGoLive(data: Partial<CampaignFormValues>) {
        const id: any = self.campaign?.id;
        if (self.campaign?.googleAdWordsAd?.isActive && self.campaign?.status.id !== '9') {
          yield self.api.unpauseExistingAdGroupsCampaign({ id });
        }
        yield self.update({
          ...data,
          status: statuses.live.id,
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.GoLive',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    save: apiFlow(
      function* save(data: Partial<CampaignFormValues>) {
        yield self.update(data);

        const callError =
          self.campaign?.googleRecentError && self.campaign?.googleRecentError.callPhoneNumberNotSupportedForCountry;
        if (callError) {
          setTimeout(() => {
            const history = self.services.get<History>('history');

            if (self.campaignKindId === campaignTypes.building.id) {
              history.replace(
                locations.campaigns.buildingCampaign.toUrl({
                  id: self.campaign?.id,
                  showWarn: Boolean(callError),
                }),
              );
              return;
            }
            if (self.campaignKindId === campaignTypes.branding.id) {
              history.replace(
                locations.campaigns.brandingCampaign.toUrl({
                  id: self.campaign?.id,
                  showWarn: Boolean(callError),
                }),
              );
              return;
            }
            if (self.campaignKindId === campaignTypes.listing.id) {
              history.replace(
                locations.campaigns.listingCampaign.toUrl({
                  id: self.campaign?.id,
                  showWarn: Boolean(callError),
                }),
              );
            }
          });
        } else {
          setTimeout(() => {
            const history = self.services.get<History>('history');
            history.push(locations.campaigns.list.toUrl());
          });
        }
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Updated',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    pause: apiFlow(
      function* pause(data: Partial<CampaignFormValues>) {
        const id: any = self.campaign?.id;

        if (self.campaign?.googleAdWordsAd?.isActive) {
          yield self.api.pauseExistingAdGroupsCampaign({ id });
        }

        yield self.update({
          ...data,
          status: statuses.paused.id,
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Paused',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),
  }))
  .actions((self) => ({
    init: apiFlow(function* init({
      id,
      campaignKindId,
      notFoundUpdateFallbackUrl,
      socialNetworksAvailability,
    }: Partial<Pick<CampaignInstanceType, 'id'>> & {
      campaignKindId: CampaignInstanceType['type']['id'];
      notFoundUpdateFallbackUrl: string;
      socialNetworksAvailability: SocialNetworksAvailability;
    }) {
      self.campaignKindId = campaignKindId;
      self.notFoundUpdateFallbackUrl = notFoundUpdateFallbackUrl;
      self.socialNetworksAvailability = socialNetworksAvailability;

      const { session } = getRoot<any>(self);

      yield Promise.all([session.loadUser(), id ? self.loadCampaign({ id }) : null]);

      yield self.socialNetworks.load();
    }),

    loadTarget: apiFlow(function* loadTarget(options: CampaignTargetLoadOptions) {
      if (!self.campaignKindId) {
        return;
      }

      yield Promise.all([self.loadTargetMain(options), self.loadAdSuggestionOptions(options)]);

      const targetId = self.target?.id;

      if (!targetId) {
        return;
      }

      yield self.loadKeywordOptions({
        targetId,
        targetKindId: 'targetKindId' in options ? options.targetKindId : undefined,
      });
    }),

    setInitialization(state: boolean) {
      self.isInitialization = state;
    },

    copy: apiFlow(
      function* copy(data: Partial<CampaignFormValues>) {
        yield self.create({
          ...omit(data, ['id', 'runDates', 'openHouseRunDates']),
          status: statuses.draft.id,
        });

        setTimeout(() => {
          if (self.campaign) {
            const history = self.services.get<History>('history');
            const location = getCampaignLocationByType(self.campaign.type.id);

            history.push(
              location.toUrl({
                id: self.campaign.id,
              }),
            );
          }
        });
      },
      {
        isUpdate: true,
        formName: 'campaign',
        successAlert: 'CampaignForm.Alerts.Copied',
        notFoundUpdateFallbackUrl: () => self.notFoundUpdateFallbackUrl,
      },
    ),

    destroy: () => {
      self.campaign = null;
      self.campaignKindId = null;
      self.notFoundUpdateFallbackUrl = null;
      self.resetTarget();
      self.targetKind = null;

      self.socialNetworks.destroy();
      self.rawBuildingOptions = cast([]);
      self.rawListingOptions = cast([]);
      self.isInitialization = true;
      self.socialNetworksAvailability = cast({});
    },
  }));

export type CampaignManagerStoreType = SnapshotOut<typeof CampaignManagerStore>;
export type CampaignManagerStoreInstanceType = Instance<typeof CampaignManagerStore>;
