import React, { memo, useMemo } from 'react';
import { Box, BoxProps } from 'components/common/box';
import { InfoIcon } from 'components/common/icon';
import { Typography, TypographyProps } from 'components/common/typography';
import { TitleProps } from './types';

const getHolderProps = (variant: TitleProps['variant']): Partial<BoxProps> => {
  switch (variant) {
    case 'h1':
      return { marginBottom: 30 };
    case 'h2':
      return { marginBottom: 50 };
    case 'h3':
      return { marginBottom: 30 };
    default:
      return {};
  }
};

const getTypographyProps = (variant: TitleProps['variant']): Partial<TypographyProps> => {
  switch (variant) {
    case 'h1':
      return { variant: 'headline-1' } as const;
    case 'h2':
      return { variant: 'headline-2' } as const;
    case 'h3':
      return { variant: 'headline-2' } as const;
    default:
      return {};
  }
};

export const Title = memo<TitleProps>(({ rightAdornment, variant = 'h2', tooltip, children, icon, ...props }) => {
  const holderProps = useMemo(() => getHolderProps(variant), [variant]);

  const typographyProps = useMemo(() => getTypographyProps(variant), [variant]);

  return (
    <Box display='flex' {...holderProps} {...props}>
      <Typography flex={1} {...typographyProps}>
        <Box is='span' verticalAlign='middle'>
          {children}
        </Box>
        {icon ? (
          <Box verticalAlign='middle' display='inline-flex' marginX={10}>
            {icon}
          </Box>
        ) : null}
        {tooltip ? (
          <Box verticalAlign='middle' display='inline-flex' marginX={10}>
            <InfoIcon tooltip={tooltip} />
          </Box>
        ) : null}
      </Typography>
      {rightAdornment ? <Box marginLeft='auto'>{rightAdornment}</Box> : null}
    </Box>
  );
});
