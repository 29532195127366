import { ListingApi, Filtration, PostListingApi, PatchListingApi } from 'types';
import { serializeFiltrationParams } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetListingsResponse,
  GetListingAmenityOptionsResponse,
  GetListingOptionsResponse,
  GetListingPropertyTypeOptionsResponse,
  GetListingTypeOptionsResponse,
  PostListingResponse,
  GetListingResponse,
  GetListingAdDescriptionSuggestionOptionsResponse,
  GetListingAdTitleSuggestionOptionsResponse,
  PatchListingResponse,
} from '../response-types';
import { createFormData } from './helpers';

const {
  getAll,
  details,
  getOptions,
  getPropertyTypeOptions,
  getAmenityOptions,
  getTypeOptions,
  post,
  patch,
  remove,
  getAdTitleSuggestionOptions,
  getAdDescriptionSuggestionOptions,
  getAdDescriptionRegenerateOptions,
  getAdTitleRegenerateOptions,
} = urls.listings;

export const createListingsApi = (http: BaseHttpClient) => ({
  getListings(options?: Filtration) {
    return http.get<GetListingsResponse>(getAll.url, serializeFiltrationParams(options));
  },

  getListing({ id }: Pick<ListingApi, 'id'>) {
    return http.get<GetListingResponse>(details.url.replace('{id}', String(id)));
  },

  getListingOptions() {
    return http.get<GetListingOptionsResponse>(getOptions.url);
  },

  getListingPropertyTypeOptions() {
    return http.get<GetListingPropertyTypeOptionsResponse>(getPropertyTypeOptions.url);
  },

  getListingAmenityOptions() {
    return http.get<GetListingAmenityOptionsResponse>(getAmenityOptions.url);
  },

  getListingTypeOptions() {
    return http.get<GetListingTypeOptionsResponse>(getTypeOptions.url);
  },

  postListing(listing: PostListingApi) {
    return http.post<PostListingResponse>(post.url, createFormData(listing));
  },

  patchListing({ id, data }: Pick<ListingApi, 'id'> & { data: Partial<PatchListingApi> }) {
    return http.patch<PatchListingResponse>(patch.url.replace('{id}', String(id)), createFormData(data));
  },

  removeListing({ id }: Pick<ListingApi, 'id'>) {
    return http.delete(remove.url.replace('{id}', String(id)));
  },

  getListingAdTitleSuggestionOptions({ id, ...options }: Filtration & Pick<ListingApi, 'id'>) {
    return http.get<GetListingAdTitleSuggestionOptionsResponse>(getAdTitleSuggestionOptions.url, {
      ...serializeFiltrationParams(options),
      listing__id: id,
    });
  },

  getListingAdDescriptionSuggestionOptions({ id, ...options }: Filtration & Pick<ListingApi, 'id'>) {
    return http.get<GetListingAdDescriptionSuggestionOptionsResponse>(getAdDescriptionSuggestionOptions.url, {
      ...serializeFiltrationParams(options),
      listing__id: id,
    });
  },

  getAdListingDescriptionRegenerateOptions({
    id,
    platform,
    // @ts-ignore
    use_case_group,
    // @ts-ignore
    asset_number,
    ...options
  }: Filtration & Pick<ListingApi, 'id'> & { platform: string }) {
    return http.get<GetListingAdDescriptionSuggestionOptionsResponse>(getAdDescriptionRegenerateOptions.url, {
      ...serializeFiltrationParams(options),
      platform,
      listing__id: id,
      use_case_group,
      asset_number,
    });
  },

  getAdListingTitleRegenerateOptions({
    id,
    platform,
    // @ts-ignore
    use_case_group,
    // @ts-ignore
    asset_number,
    ...options
  }: Filtration & Pick<ListingApi, 'id'> & { platform: string }) {
    return http.get<GetListingAdDescriptionSuggestionOptionsResponse>(getAdTitleRegenerateOptions.url, {
      ...serializeFiltrationParams(options),
      platform,
      listing__id: id,
      use_case_group,
      asset_number,
    });
  },
});
