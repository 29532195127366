import { cast, getRoot, Instance, SnapshotOut, types } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import OptionWithOldMarker, { OptionWithOldMarkerSnapshot } from 'stores/models/option-with-old-marker';
import { apiFlow } from 'stores/mst-types';
import { CampaignTargetInstanceType } from 'stores/models/campaigns/campaign-target';
import config from 'config';
import { cloneDeep } from 'lodash';
import { AdGroupOptionSnapshot } from '../ads/ad-group';

const { platforms } = config;

const isActualOption = ({ isActive }: OptionWithOldMarkerSnapshot): boolean => Boolean(isActive);

const isPlatformOption = ({ platform }: OptionWithOldMarkerSnapshot, platformType: string): boolean =>
  !platform || platform === platformType;

const CampaignTextSuggestions = BaseModel.named('CampaignTextSuggestions')
  .props({
    titles: types.optional(types.array(OptionWithOldMarker), []),
    descriptions: types.optional(types.array(OptionWithOldMarker), []),
  })
  .views((self) => ({
    get defaultTitle() {
      return [...self.titles].filter(isActualOption);
    },

    get defaultDescription() {
      return [...self.descriptions].filter(isActualOption);
    },

    get defaultFacebookDescription() {
      return [...self.descriptions].filter(
        (description) => isActualOption(description) && isPlatformOption(description, platforms.facebookPlatform),
      );
    },

    get defaultGoogleDescription() {
      return [...self.descriptions].filter(
        (description) => isActualOption(description) && isPlatformOption(description, platforms.googlePlatform),
      );
    },

    get defaultFacebookTitle() {
      return [...self.titles].filter(
        (title) => isActualOption(title) && isPlatformOption(title, platforms.facebookPlatform),
      );
    },

    get defaultGoogleTitle() {
      return [...self.titles].filter(
        (title) => isActualOption(title) && isPlatformOption(title, platforms.googlePlatform),
      );
    },
  }))
  .actions((self) => ({
    loadBrandingUserTitles: apiFlow(function* loadBrandingUserTitles() {
      const { getBrandingUserAdTitleSuggestionOptions } = getRoot<any>(self).common;

      self.titles = yield getBrandingUserAdTitleSuggestionOptions();
    }),

    loadBrandingUserDescriptions: apiFlow(function* loadBrandingUserDescriptions() {
      const { getBrandingUserAdDescriptionSuggestionOptions } = getRoot<any>(self).common;

      self.descriptions = yield getBrandingUserAdDescriptionSuggestionOptions();
    }),

    loadBrandingOrganizationTitles: apiFlow(function* loadBrandingOrganizationTitles() {
      const { getBrandingOrganizationAdTitleSuggestionOptions } = getRoot<any>(self).common;

      self.titles = yield getBrandingOrganizationAdTitleSuggestionOptions();
    }),

    loadBrandingOrganizationDescriptions: apiFlow(function* loadBrandingOrganizationDescriptions() {
      const { getBrandingOrganizationAdDescriptionSuggestionOptions } = getRoot<any>(self).common;

      self.descriptions = yield getBrandingOrganizationAdDescriptionSuggestionOptions();
    }),

    loadBuildingTitles: apiFlow(function* loadBuildingTitles({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const { getBuildingAdTitleSuggestionOptions } = getRoot<any>(self).common;

      self.titles = yield getBuildingAdTitleSuggestionOptions({
        id,
      });
    }),

    loadBuildingDescriptions: apiFlow(function* loadBuildingDescriptions({
      id,
    }: Pick<CampaignTargetInstanceType, 'id'>) {
      const { getBuildingAdDescriptionSuggestionOptions } = getRoot<any>(self).common;

      self.descriptions = yield getBuildingAdDescriptionSuggestionOptions({
        id,
      });
    }),

    loadBuildingDescriptionRegenerateForGroup: apiFlow(function* loadBuildingDescriptionRegenerateForGroup({
      id,
      platform,
      // @ts-ignore
      selectedGroup,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdBuildingDescriptionRegenerateOptions } = getRoot<any>(self).common;
      const { adGroups, setAdGroups } = getRoot<any>(self).googleSearchAd;
      const regenerated = yield getAdBuildingDescriptionRegenerateOptions({
        id,
        platform,
        selectedGroup,
        use_case_group,
        asset_number,
      });
      const newRegeneratedDescriptions = regenerated.map((e: any) => ({
        asset_number: e.asset_number,
        label: e.label,
        use_case_group: e.use_case_group,
        value: e.value,
      }));

      if (Array.isArray(adGroups) && adGroups && adGroups.length > 0) {
        const updatedAdGroups = adGroups.map((group: any, index: number) => {
          if (group.descriptions && group.descriptions.length > 0 && index === selectedGroup) {
            return { ...group, descriptions: [...group.descriptions, ...newRegeneratedDescriptions] };
          }
          return group;
        }) as AdGroupOptionSnapshot[];
        setAdGroups(cloneDeep(updatedAdGroups));
      }
      return newRegeneratedDescriptions;
    }),
    loadBuildingTitleRegenerateForGroup: apiFlow(function* loadBuildingTitleRegenerateForGroup({
      id,
      platform,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
      // @ts-ignore
      selectedGroup,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdBuildingTitleRegenerateOptions } = getRoot<any>(self).common;
      const { adGroups, setAdGroups } = getRoot<any>(self).googleSearchAd;
      const regenerated = yield getAdBuildingTitleRegenerateOptions({
        id,
        platform,
        selectedGroup,
        use_case_group,
        asset_number,
      });

      const newRegeneratedTitle = regenerated.map((e: any) => ({
        asset_number: e.asset_number,
        label: e.label,
        use_case_group: e.use_case_group,
        value: e.value,
      }));

      if (Array.isArray(adGroups) && adGroups && adGroups.length > 0) {
        const updatedAdGroups = adGroups.map((group: any, index: number) => {
          if (group.titles && group.titles.length > 0 && index === selectedGroup) {
            return { ...group, titles: [...group.titles, ...newRegeneratedTitle] };
          }
          return group;
        }) as AdGroupOptionSnapshot[];
        setAdGroups(cloneDeep(updatedAdGroups));
      }
      return newRegeneratedTitle;
    }),

    loadBuildingDescriptionRegenerate: apiFlow(function* loadBuildingDescriptionRegenerate({
      id,
      platform,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdBuildingDescriptionRegenerateOptions } = getRoot<any>(self).common;
      const { isABTesting, abTestingOverview, setRegenerateDescription, toggleActive } =
        getRoot<any>(self).abTestingCampaign;

      const regenerated = yield getAdBuildingDescriptionRegenerateOptions({
        id,
        platform,
        // @ts-ignore
        use_case_group,
        // @ts-ignore
        asset_number,
      });

      if (isABTesting || toggleActive) {
        const description = regenerated.map(({ label, value }: any) => ({
          label,
          value,
        }));

        setRegenerateDescription([...description, ...abTestingOverview.variantB.descriptions]);
      } else {
        self.descriptions = [...regenerated, ...self.descriptions] as any;
      }
    }),

    loadBuildingTitleRegenerate: apiFlow(function* loadBuildingTitleRegenerate({
      id,
      platform,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdBuildingTitleRegenerateOptions } = getRoot<any>(self).common;
      const { isABTesting, abTestingOverview, setRegenerateTitle, toggleActive } = getRoot<any>(self).abTestingCampaign;
      const regenerated = yield getAdBuildingTitleRegenerateOptions({
        id,
        platform,
        // @ts-ignore
        use_case_group,
        // @ts-ignore
        asset_number,
      });
      if (isABTesting || toggleActive) {
        const title = regenerated.map(({ label, value }: any) => ({
          label,
          value,
        }));

        setRegenerateTitle([...title, ...abTestingOverview.variantB.titles]);
      } else {
        self.titles = [...regenerated, ...self.titles] as any;
        return regenerated;
      }
      return regenerated;
    }),

    loadListingTitleRegenerate: apiFlow(function* loadListingTitleRegenerate({
      id,
      platform,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdListingTitleRegenerateOptions } = getRoot<any>(self).common;
      const { isABTesting, abTestingOverview, setRegenerateTitle, toggleActive } = getRoot<any>(self).abTestingCampaign;

      const regenerated = yield getAdListingTitleRegenerateOptions({
        id,
        platform,
        // @ts-ignore
        use_case_group,
        // @ts-ignore
        asset_number,
      });

      if (isABTesting || toggleActive) {
        const title = regenerated.map(({ label, value }: any) => ({
          label,
          value,
        }));

        setRegenerateTitle([...title, ...abTestingOverview.variantB.titles]);
      } else {
        self.titles = [...regenerated, ...self.titles] as any;
        return regenerated;
      }
      return regenerated;
    }),

    loadBrandingTitleRegenerate: apiFlow(function* loadBrandingDescriptionRegenerate({
      typeId,
      platform,
    }: {
      typeId: string;
      platform: string;
    }) {
      const { getAdBrandingTitleRegenerateOptions } = getRoot<any>(self).common;

      const regenerated = yield getAdBrandingTitleRegenerateOptions({
        typeId,
        platform,
      });
      self.titles = [...regenerated, ...self.titles] as any;
    }),

    loadListingTitles: apiFlow(function* loadListingTitles({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const { getListingAdTitleSuggestionOptions } = getRoot<any>(self).common;

      self.titles = yield getListingAdTitleSuggestionOptions({
        id,
      });
    }),

    loadListingDescriptions: apiFlow(function* loadListingDescriptions({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      const { getListingAdDescriptionSuggestionOptions } = getRoot<any>(self).common;

      self.descriptions = yield getListingAdDescriptionSuggestionOptions({
        id,
      });
    }),

    loadListingDescriptionRegenerate: apiFlow(function* loadListingDescriptionRegenerate({
      id,
      platform,
      // @ts-ignore
      use_case_group,
      // @ts-ignore
      asset_number,
    }: Pick<CampaignTargetInstanceType, 'id'> & { platform: string }) {
      const { getAdListingDescriptionRegenerateOptions } = getRoot<any>(self).common;

      const regenerated = yield getAdListingDescriptionRegenerateOptions({
        id,
        platform,
        // @ts-ignore
        use_case_group,
        // @ts-ignore
        asset_number,
      });
      self.descriptions = [...regenerated, ...self.descriptions] as any;
    }),

    loadBrandingDescriptionRegenerate: apiFlow(function* loadBrandingDescriptionRegenerate({
      typeId,
      platform,
    }: {
      typeId: string;
      platform: string;
    }) {
      const { getAdBrandingDescriptionRegenerateOptions } = getRoot<any>(self).common;

      const regenerated = yield getAdBrandingDescriptionRegenerateOptions({
        typeId,
        platform,
      });
      self.descriptions = [...regenerated, ...self.descriptions] as any;
    }),
  }))
  .actions((self) => ({
    loadBuildingSuggestions: apiFlow(function* loadBuildingSuggestions({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      yield Promise.all([
        self.loadBuildingTitles({
          id,
        }),
        self.loadBuildingDescriptions({
          id,
        }),
      ]);
    }),

    loadListingSuggestions: apiFlow(function* loadListingSuggestions({ id }: Pick<CampaignTargetInstanceType, 'id'>) {
      yield Promise.all([
        self.loadListingTitles({
          id,
        }),
        self.loadListingDescriptions({
          id,
        }),
      ]);
    }),

    loadBrandingUserSuggestions: apiFlow(function* loadBrandingUserSuggestions() {
      yield Promise.all([self.loadBrandingUserTitles(), self.loadBrandingUserDescriptions()]);
    }),

    loadBrandingOrganizationSuggestions: apiFlow(function* loadBrandingOrganizationSuggestions() {
      yield Promise.all([self.loadBrandingOrganizationTitles(), self.loadBrandingOrganizationDescriptions()]);
    }),

    destroy: () => {
      self.titles = cast([]);
      self.descriptions = cast([]);
    },
  }));

export type CampaignTextSuggestionsInstanceType = Instance<typeof CampaignTextSuggestions>;
export type CampaignTextSuggestionsSnapshot = SnapshotOut<typeof CampaignTextSuggestions>;

export default CampaignTextSuggestions;
