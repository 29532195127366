import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Option, OptionFromValue } from 'types';
import config from 'config';
import themeColors from 'utils/theme-colors';
import { Box } from 'components/common/box';
import { GridLayout } from 'components/common/grid-layout';
import { Link } from 'components/common/link';
import { withFormItem } from 'hocs/with-form-item';
import { useField, useForm } from 'hooks';
import { useCampaignFormContext } from 'components/campaigns/campaign-form/hooks';
import { TextPickerRow } from 'components/common/form/ad-text-picker/text-picker-row';
import { AddAdditionalButton } from 'components/common/form/ad-text-picker/add-additional-button';
import { randomNumber } from 'utils/numbers';
import { maxIndex } from 'components/common/form/ad-text-picker/utils';
import {
  GOOGLE_ADS_FIELD,
  GOOGLE_ADWORDS_FIELD,
  FACEBOOK_FIELD,
  INSTAGRAM_FIELD,
} from 'components/campaigns/campaign-form/ad-social-networks-selector';
import { TextPickerContext } from './context';
import { TextPickerProps } from './types';
import left from './left.svg';
import right from './right.svg';
import shuffle from './shuffle.svg';
import './index.css';

const { facebookPlatform, googlePlatform } = config.platforms;

const defaultOptions = [] as NonNullable<TextPickerProps['options']>;

export const TextPicker = withFormItem<TextPickerProps>()(
  ({ options: optionsFromProps = defaultOptions, placeholder, labelText, tabName, ...props }) => {
    const { field, componentProps } = useField(props);
    const { name, value, onChange } = field;

    const [regenerateInputIndex, setRegenerateInputIndex] = useState(-1);
    const [regenerateInputTitleIndex, setRegenerateInputTitleIndex] = useState(-1);
    const [addDescription, setAddDescription] = useState(true);
    const [addDescriptionTrigger, setAddDescriptionTrigger] = useState(true);

    const form = useForm();
    const { setFieldTouched, setFieldValue } = useForm();
    const formRef = useRef(form);
    formRef.current = form;

    const platform = useMemo(() => {
      if (
        tabName === FACEBOOK_FIELD ||
        tabName === INSTAGRAM_FIELD ||
        tabName === 'variantAbTesting' ||
        tabName === 'variantB'
      ) {
        return facebookPlatform;
      }
      if (tabName === GOOGLE_ADS_FIELD || tabName === GOOGLE_ADWORDS_FIELD) {
        return googlePlatform;
      }
      return '';
    }, [tabName]);

    const indexedValueItems = useMemo<(OptionFromValue & { id: string })[]>(
      () =>
        (value as OptionFromValue[])?.slice(0, 15).map((item: OptionFromValue) => ({
          id: uuidv4(),
          ...item,
        })),
      // eslint-disable-next-line  react-hooks/exhaustive-deps
      [value.length],
    );

    const updatedOptions = useMemo(
      () =>
        optionsFromProps.map((option) => {
          const valueItem = indexedValueItems?.find(({ value }) => option.value === value);
          return {
            ...option,
            label: valueItem && valueItem.label ? valueItem.label : option.label,
          };
        }),
      [indexedValueItems, optionsFromProps],
    );

    const [options, setOptions] = useState(updatedOptions);

    useEffect(() => {
      setOptions(updatedOptions);
    }, [updatedOptions]);

    const {
      campaignManager: { target, loadAdDescriptionRegenerateOptions, loadAdTitleRegenerateOptions },
    } = useCampaignFormContext();

    const selectedOption = useCallback(
      (index: number) => {
        if (value) {
          if (regenerateInputIndex >= 0) {
            form.setFieldValue(`descriptions[${regenerateInputIndex}].value`, options[0]?.value);
            form.setFieldValue(`descriptions[${regenerateInputIndex}].label`, options[0]?.label);
            setRegenerateInputIndex(-1);
          }

          if (Array.isArray(value)) {
            return options.find(({ value: optionLabel }) => optionLabel === value[index].value);
          }

          return options.find(({ value: optionLabel }) => optionLabel === value.value);
        }
        return null;
      },
      [form, options, regenerateInputIndex, value],
    );

    const selectedTitleOption = useCallback(
      (index: number) => {
        if (value) {
          if (regenerateInputTitleIndex >= 0) {
            form.setFieldValue(`titles[${regenerateInputTitleIndex}].value`, options[0]?.value);
            form.setFieldValue(`titles[${regenerateInputTitleIndex}].label`, options[0]?.label);
            setRegenerateInputTitleIndex(-1);
          }

          if (Array.isArray(value)) {
            return options.find(({ value: optionLabel }) => optionLabel === value[index].value);
          }

          return options.find(({ value: optionLabel }) => optionLabel === value.value);
        }
        return null;
      },
      [form, options, regenerateInputTitleIndex, value],
    );

    const visibleOptions = useCallback(
      (currentIndex: number) => {
        const otherInputValues = Array.isArray(value)
          ? value.filter((_, index) => index !== currentIndex).map((val) => val.value)
          : [];

        return options.filter(
          ({ isOld, value: optionLabel }) =>
            optionLabel === value[currentIndex].value || (!isOld && !otherInputValues.includes(optionLabel)),
        );
      },
      [options, value],
    );

    const selectedOptionIndex = useCallback(
      // @ts-ignore
      (index: number) => visibleOptions(index).indexOf(selectedOption(index) as Option),
      [visibleOptions, selectedOption],
    );
    const selectedTitleOptionIndex = useCallback(
      // @ts-ignore
      (index: number) => visibleOptions(index).indexOf(selectedTitleOption(index) as Option),
      [visibleOptions, selectedTitleOption],
    );

    const isSelectedOptionOld = useCallback((index) => selectedOption(index)?.isOld, [selectedOption]);

    const total = useCallback((index: number) => visibleOptions(index).length, [visibleOptions]);

    const selectOption = useCallback(
      (option: Nullable<Option>, index: number) => {
        if (option) {
          onChange({
            target: {
              name: `${name}[${index}]`,
              value: { value: option.value, label: option.label },
            },
          });
        }
      },
      [name, onChange],
    );

    const onPrevClick = useCallback(
      (index: number) => {
        const options = visibleOptions(index);
        const prevIndex = selectedOptionIndex(index) - 1;
        selectOption(options[prevIndex < 0 ? options.length - 1 : prevIndex], index);
      },
      [visibleOptions, selectOption, selectedOptionIndex],
    );

    const onNextClick = useCallback(
      (index: number) => {
        const options = visibleOptions(index);
        const nextIndex = selectedOptionIndex(index) + 1;
        selectOption(options[nextIndex >= options.length ? 0 : nextIndex], index);
      },
      [visibleOptions, selectOption, selectedOptionIndex],
    );

    const onShuffleClick = useCallback(
      (index: number) => {
        selectOption(
          visibleOptions(index)[
            randomNumber({
              min: 0,
              max: total(index),
              exclude: selectedOptionIndex(index),
            })
          ],
          index,
        );
      },
      [visibleOptions, selectOption, total, selectedOptionIndex],
    );

    const freeOptions = useCallback(
      (options: any) => {
        const filteredOptions = options.map((option: any) => ({
          value: option.value,
          label: option.label,
        }));
        const filteredValues = value.map((value: any) => ({
          label: value.label,
        }));

        return filteredOptions.filter((value: any) => filteredValues.every((item: any) => item.label !== value.label));
      },
      [value],
    );

    const onAdd = useCallback(() => {
      setAddDescription(true);
      const freeOption = freeOptions(optionsFromProps);
      const emptyValue = freeOption.length !== 0 ? freeOption.splice(0, 1)[0] : {};
      onChange({
        target: {
          name,
          value: [...value, emptyValue],
        },
      });
    }, [name, onChange, value, freeOptions, optionsFromProps]);

    const onRemove = useCallback(
      (rowIndex: number) => {
        setAddDescription(false);
        const nextRawValue = value && value.filter((_: any, index: number) => index !== rowIndex);

        onChange({ target: { name, value: nextRawValue } });
        setFieldTouched('titles', true);
        setFieldTouched('descriptions', true);
      },
      [name, onChange, value, setFieldTouched],
    );

    const onRegenerateDescriptionsClick = useCallback(
      (index: number, use_case_group: string, asset_number: number) => {
        (async () => {
          if (target) {
            await loadAdDescriptionRegenerateOptions({
              id: target?.id,
              platform,
              // @ts-ignore
              use_case_group:
                tabName === 'variantB'
                  ? 'meta_default'
                  : tabName === 'facebookAd'
                  ? 'meta_default'
                  : tabName === 'instagramAd'
                  ? 'meta_default'
                  : tabName === 'googleAdsAd'
                  ? 'general_display_targeting'
                  : optionsFromProps[index]?.use_case_group,
              // @ts-ignore
              asset_number: index,
            });

            setOptions(updatedOptions);
            setRegenerateInputIndex(index);
          }
        })();
      },
      [loadAdDescriptionRegenerateOptions, optionsFromProps, platform, target, updatedOptions, tabName],
    );

    const onRegenerateTitlesClick = useCallback(
      (index: number, use_case_group: string, asset_number: number) => {
        (async () => {
          if (target) {
            const result = await loadAdTitleRegenerateOptions({
              id: target?.id,
              platform,
              // @ts-ignore
              use_case_group:
                tabName === 'variantB'
                  ? 'meta_default'
                  : tabName === 'facebookAd'
                  ? 'meta_default'
                  : tabName === 'instagramAd'
                  ? 'meta_default'
                  : tabName === 'googleAdsAd'
                  ? 'general_display_targeting'
                  : optionsFromProps[index]?.use_case_group,
              // @ts-ignore
              asset_number: index,
            });
            setFieldValue(`titles[${index}]`, result[0]);
          }
        })();
      },
      [loadAdTitleRegenerateOptions, optionsFromProps, platform, target, tabName, setFieldValue],
    );

    const isPrevDisabled = useCallback(
      (index) =>
        optionsFromProps.length <= 2 || tabName === 'facebookAd' || tabName === 'instagramAd'
          ? total(index) <= 1
          : total(0) <= 1 && total(1) <= 1,
      [total, tabName, optionsFromProps],
    );
    const isNextDisabled = useCallback(
      (index) =>
        optionsFromProps.length <= 2 || tabName === 'facebookAd' || tabName === 'instagramAd'
          ? total(index) <= 1
          : total(0) <= 1 && total(1) <= 1,
      [total, tabName, optionsFromProps],
    );
    const isShuffleDisabled = useCallback(
      (index) =>
        optionsFromProps.length <= 2 || tabName === 'facebookAd' || tabName === 'instagramAd'
          ? total(index) <= 1
          : total(0) <= 1 && total(1) <= 1,
      [total, tabName, optionsFromProps],
    );

    const controls = useCallback(
      (index: number) =>
        [
          {
            label: (
              <div style={{ width: '40px', marginRight: '8px' }} className='arrow-btn'>
                <img style={{ opacity: isPrevDisabled(index) ? '0.3' : '1' }} src={left} alt='' />
              </div>
            ),
            onClick: () => onPrevClick(index),
            disabled: isPrevDisabled(index),
          },
          {
            label: (
              <div style={{ width: '40px', marginRight: '24px' }} className='arrow-btn'>
                <img style={{ opacity: isNextDisabled(index) ? '0.3' : '1' }} src={right} alt='' />
              </div>
            ),
            onClick: () => onNextClick(index),
            disabled: isNextDisabled(index),
          },
          {
            label: (
              <div className='arrow-btn' style={{ width: '90px' }}>
                <img
                  src={shuffle}
                  alt=''
                  style={{
                    marginRight: '10px',
                    opacity: isShuffleDisabled(index) ? '0.3' : '1',
                  }}
                />{' '}
                Shuffle
              </div>
            ),
            onClick: () => onShuffleClick(index),
            disabled: isShuffleDisabled(index),
          },
        ].map(({ label, ...controlProps }, index) => (
          <Link key={index} variant='body-3' underline='none' {...controlProps}>
            {label}
          </Link>
        )),
      [onPrevClick, isPrevDisabled, onNextClick, isNextDisabled, onShuffleClick, isShuffleDisabled],
    );

    const borderColor = useCallback(
      (index: number) => (isSelectedOptionOld(index) ? themeColors.grey13 : themeColors.grey13),
      [isSelectedOptionOld],
    );

    const contextValue = useMemo(
      () => ({
        tabName,
        placeholder,
        labelText,
      }),
      [labelText, placeholder, tabName],
    );

    const items = useMemo(
      () =>
        indexedValueItems.map(({ id }, index) => (
          <TextPickerRow
            key={id}
            rootFieldName={name}
            rowIndex={index}
            selectedOptionIndex={selectedOptionIndex}
            total={total}
            controls={controls}
            isSelectedOptionOld={isSelectedOptionOld}
            borderColor={borderColor(index)}
            onRemove={onRemove}
            options={options}
            setOptions={setOptions}
            valueLength={value}
            selectedOption={name === 'titles' ? selectedTitleOption : selectedOption}
            selectOption={selectOption}
            onRegenerateDescriptionsClick={onRegenerateDescriptionsClick}
            onRegenerateTitlesClick={onRegenerateTitlesClick}
          />
        )),
      [
        selectedTitleOption,
        indexedValueItems,
        name,
        selectedOptionIndex,
        total,
        controls,
        isSelectedOptionOld,
        borderColor,
        onRemove,
        options,
        selectedOption,
        selectOption,
        onRegenerateDescriptionsClick,
        onRegenerateTitlesClick,
        value,
      ],
    );

    const columns = useMemo(() => (Array.isArray(items) && items.length > 1 ? 1 : 1), [items]);

    const hideAddAdditionalButton = indexedValueItems.length === maxIndex(name, tabName);

    return (
      <TextPickerContext.Provider value={contextValue}>
        <Box {...componentProps}>
          <GridLayout columns={columns} gridColumnGap={120} gridRowGap={30} marginBottom={30}>
            {items}
          </GridLayout>
          {hideAddAdditionalButton ? null : (
            <AddAdditionalButton style={{ marginBottom: '20px' }} name={name} onAdd={onAdd} />
          )}
        </Box>
      </TextPickerContext.Provider>
    );
  },
);
