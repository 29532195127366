import { BuildingApi, PostBuildingApi, PostYardiApi, PatchBuildingApi, Filtration } from 'types';
import { serializeFiltrationParams } from 'utils/serializers';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import {
  GetBuildingOptionsResponse,
  GetBuildingAmenityOptionsResponse,
  GetBuildingCompetitorsOptionsResponse,
  GetMasstransitOptionsResponse,
  GetBuildingsResponse,
  GetBuildingResponse,
  PostBuildingResponse,
  PatchBuildingResponse,
  GetBuildingPropertyTypeOptionsResponse,
  GetBuildingAdTitleSuggestionOptionsResponse,
  GetBuildingAdDescriptionSuggestionOptionsResponse,
} from '../response-types';
import { createFormData } from './helpers';

const {
  getAll,
  details,
  getYardiId,
  post,
  postYardi,
  sync,
  patch,
  patchYardiId,
  remove,
  getOptions,
  getAmenityOptions,
  getYardiConnectionStatus,
  getCompetitorsOptions,
  getMasstransitOptions,
  getPropertyTypeOptions,
  getAdTitleSuggestionOptions,
  getAdDescriptionSuggestionOptions,
  getAdDescriptionRegenerateOptions,
  getAdTitleRegenerateOptions,
} = urls.buildings;

export const createBuildingsApi = (http: BaseHttpClient) => ({
  getBuildings(options?: Filtration) {
    return http.get<GetBuildingsResponse>(getAll.url, serializeFiltrationParams(options));
  },

  getBuilding({ id }: Pick<BuildingApi, 'id'>) {
    return http.get<GetBuildingResponse>(details.url.replace('{id}', String(id)));
  },
  getYardiId(id: number) {
    return http.get<any>(getYardiId.url.replace('{id}', String(id)));
  },

  updateYardiId({
    property_id,
    id,
    is_autosync_enabled,
  }: Pick<any, 'id'> & { property_id: any; is_autosync_enabled: boolean }) {
    return http.put<PatchBuildingResponse>(patchYardiId.url.replace('{id}', String(id)), {
      property_id,
      is_autosync_enabled,
    });
  },

  syncWithYardi(id: number) {
    return http.get<any>(sync.url.replace('{id}', String(id)));
  },

  postBuilding(building: PostBuildingApi) {
    return http.post<PostBuildingResponse>(post.url, createFormData(building));
  },

  postYardiPropertyId(yardi: PostYardiApi) {
    return http.post<any>(postYardi.url, yardi);
  },

  patchBuilding({ id, data }: Pick<BuildingApi, 'id'> & { data: Partial<PatchBuildingApi> }) {
    return http.patch<PatchBuildingResponse>(patch.url.replace('{id}', String(id)), createFormData(data));
  },

  removeBuilding({ id }: Pick<BuildingApi, 'id'>) {
    return http.delete(remove.url.replace('{id}', String(id)));
  },

  getBuildingOptions() {
    return http.get<GetBuildingOptionsResponse>(getOptions.url);
  },

  getBuildingPropertyTypeOptions() {
    return http.get<GetBuildingPropertyTypeOptionsResponse>(getPropertyTypeOptions.url);
  },

  getBuildingAmenityOptions() {
    return http.get<GetBuildingAmenityOptionsResponse>(getAmenityOptions.url);
  },

  getYardiConnectionStatus() {
    return http.get<any>(getYardiConnectionStatus.url);
  },

  getBuildingCompetitorOptions() {
    return http.get<GetBuildingCompetitorsOptionsResponse>(getCompetitorsOptions.url);
  },

  getMasstransitOptions() {
    return http.get<GetMasstransitOptionsResponse>(getMasstransitOptions.url);
  },

  getBuildingAdTitleSuggestionOptions({ id, ...options }: Filtration & Pick<BuildingApi, 'id'>) {
    return http.get<GetBuildingAdTitleSuggestionOptionsResponse>(getAdTitleSuggestionOptions.url, {
      ...serializeFiltrationParams(options),
      building__id: id,
      use_case_group: ['meta_default', 'general_display_targeting'],
    });
  },

  getBuildingAdDescriptionSuggestionOptions({ id, ...options }: Filtration & Pick<BuildingApi, 'id'>) {
    // TODO relocate hardcoded use case group to another place
    return http.get<GetBuildingAdDescriptionSuggestionOptionsResponse>(getAdDescriptionSuggestionOptions.url, {
      ...serializeFiltrationParams(options),
      building__id: id,
      use_case_group: ['meta_default', 'general_display_targeting'],
    });
  },

  getAdBuildingDescriptionRegenerateOptions({
    id,
    platform,
    // @ts-ignore
    selectedGroup,
    // @ts-ignore
    use_case_group,
    // @ts-ignore
    asset_number,
    ...options
  }: Filtration & Pick<BuildingApi, 'id'> & { platform: string }) {
    return http.get<GetBuildingAdDescriptionSuggestionOptionsResponse>(getAdDescriptionRegenerateOptions.url, {
      ...serializeFiltrationParams(options),
      platform,
      building__id: id,
      selected_group: selectedGroup,
      use_case_group,
      asset_number,
    });
  },

  getAdBuildingTitleRegenerateOptions({
    id,
    platform,
    // @ts-ignore
    selectedGroup,
    // @ts-ignore
    use_case_group,
    // @ts-ignore
    asset_number,
    ...options
  }: Filtration & Pick<BuildingApi, 'id'> & { platform: string }) {
    return http.get<GetBuildingAdDescriptionSuggestionOptionsResponse>(getAdTitleRegenerateOptions.url, {
      ...serializeFiltrationParams(options),
      platform,
      building__id: id,
      selected_group: selectedGroup,
      use_case_group,
      asset_number,
    });
  },
});
