import config from 'config';
import bytes from 'bytes';

const { validations, imageUploader, logoUploader } = config.options;

const en = {
  Common: {
    EmptyValue: '--',
    Validations: {
      Required: 'Field is required',
      AdSocials: 'At least one field is required',
      EmailFormat: 'Email format is invalid',
      StartDateRequired: 'Start date is required',
      EndDateRequired: 'End date is required',
      StartDateCannotBePast: 'Start date cannot be the current date or past date',
      StartDateCannotBeTheCurrentDate:
        'Start date cannot be the current date. Please choose a different date and try again.',
      DateCannotBeTheSame: 'Start date and End date cannot be the same. Please choose different dates and try again.',
      DateMustBeThisYearOrNextYear:
        'Start date and End date must be this year or next year. Please choose different dates and try again.',
      UrlFormat: 'Url is invalid',
      ZipFormat: `Zip should be ${validations.zipMinLength} digits`,
      SpecialChar: `Field can't use special characters [$&+,:;=?@#|'<>.-^*()%!]`,
      PhoneFormat: `Should be valid phone number`,
      StringMinLength: 'Should be minimum {{amount}} characters',
      StringMaxLength: 'Should be maximum {{amount}} characters',
      PositiveNumber: 'Must be a positive number',
      NumberMax: 'Should be maximum {{amount}}',
      PasswordUppercaseCharacter: 'Should contain at least 1 uppercase character',
      PasswordLowercaseCharacter: 'Should contain at least 1 lowercase character',
      PasswordSpecialCharacter: 'Should contain at least 1 special character',
      PasswordDigit: 'Should contain at least 1 digit',
      ConfirmPassword: 'Passwords must match',
      Accept: 'Please accept the Terms of Service and Privacy Policy',
      InvalidKeywordFormat: 'One of the keywords has inappropriate characters',
      KeywordMinLength: 'At least one keyword should be selected',
      MinimumBudget: `minimum daily budget is \u0024{{price}}`,
      DescriptionDuplication: 'Descriptions are duplicated! Please, enter a unique description!',
      DescriptionDuplicationMin: 'Descriptions are duplicated!',
      TitleDuplication: 'Titles are duplicated! Please, enter a unique title!',
      TitleDuplicationMin: 'Titles are duplicated!',
      KeywordsDuplicationMin: 'Keywords are duplicated!',
      CompetitorsDuplication: 'Competitors are duplicated! Please, enter a unique value!',
      MasstransitDuplication: 'Mass transit are duplicated! Please, enter a unique value!',
      AmenitiesDuplication: 'Amenities are duplicated! Please, enter a unique value!',
      OnlyOneExclamationMark: 'Only one exclamation mark is allowed',
      noExclamationMark: 'Exclamation mark is not allowed',
      noQuestionErrorMark: 'Only one question mark is allowed',
      SpecialSymbolsError: 'At sign, asterisk, bullet point, and ellipses are not allowed',
      GroupUnique: 'Ad group name should be unique',
    },

    PageErrors: {
      AppCrashed: 'App crashed',
    },
    SomethingWentWrong: 'Something Went Wrong',
    HardResetLink: 'Reload',
    HomeLink: 'Home',
    OkButton: 'Ok',
    DetailsLink: 'Details',
    LinkButton: 'Link',
    Alerts: {
      Success: 'Success!',
      Error: 'Failure!',
      NotFoundUpdate: 'This page is no longer available',
    },
    Version: 'Web: {{packageVersion}}, Api: {{apiVersion}}',
    OrganizationKindOptions: {
      Agent: 'Agent',
      AgentDescription: 'For individual agents or brokers who represent, buyers, sellers, and rentals.',
      Brokerage: 'Brokerages, Teams or Property managers',
      BrokerageDescription:
        'For brokerages, teams, and property manager who need multiple users or who manage multiple clients’ assets.',
      Multifamily: 'Multifamily Organizations',
      MultifamilyDescription:
        'For owners of multifamily assets who manage their own marketing and advertising campaigns internally.',
    },
    AdSocialNetworkOptions: {
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      GoogleAdWords: 'Google search',
      GoogleAds: 'Display',
    },
  },
  RouteLeavingGuard: {
    Title: 'Save changes',
    Message:
      'Are you sure you want to leave this page without saving changes? This way you will lose the data you have entered',
    CancelButton: 'Cancel',
    ConfirmButton: 'Leave',
  },

  PageNotFound: {
    Title: '404',
    SubTitle: 'Oops! Page Not Found',
    Description: 'Page you are looking can not be displayed',
    HomepageButton: 'Back to Homepage',
  },
  ErrorPage: {
    SubTitle: 'Oops! Something went wrong...',
    Description: 'Page you are looking for isn’t available.',
    RefreshButton: 'Refresh page',
  },

  SuperUser: {
    Labels: {
      Organization: 'Organization',
      User: 'User',
    },
    Placeholders: {
      Organization: 'Organization',
      User: 'User',
    },
    Button: 'Back',
  },

  Auth: {
    WelcomeGallery: {
      Title: 'A.I. POWERED ADVERTISING <0>FOR REAL ESTATE</0>',
      FirstSlideTitle: 'Bait The Digital Hook',
      FirstSlideDescription:
        'Minerva’s A.I. analyzes your listings and determines the copy most likely to hook people ready to buy, then automatically reaches them where they are most likely to be.',
      SecondSlideTitle: 'Keep Customers In Your Funnel',
      SecondSlideDescription:
        'Your leads see the right ad at the right time in the right place. When they click on a Minerva- served ad, they click through to your site. You control the interaction, not the big boys.',
      ThirdSlideTitle: 'Reach A Bigger Audience',
      ThirdSlideDescription:
        'Minerva’s A.I. gets to know you and your clientele. The more ads it runs for you, the smarter it gets, automatically generating new leads specifically interested in your properties.',
    },

    SignUp: {
      Title: 'Sign up',
      Labels: {
        Name: 'Name',
        LastName: 'Last name',
        Email: 'E-mail',
        State: 'State',
        City: 'City',
        Password: 'Password',
        ConfirmPassword: 'Confirm password',
        Accept: 'I accept the Minerva <0>Terms of Service</0> and <1>Privacy Policy</1>',
        Phone: 'Phone',
        PromoCode: 'Promo code',
      },
      Placeholders: {
        Name: 'Name',
        LastName: 'Last name',
        Email: 'E-mail',
        State: 'State',
        City: 'City',
        Password: 'Password',
        ConfirmPassword: 'Confirm password',
        Phone: 'Phone',
        PromoCode: 'Promo code',
      },
      SubmitButton: 'Sign up',
      AlternativeAction: 'Have an account? <0>Log in</0> now',
      SuccessTitle: 'Email confirmation',
      SuccessDescription:
        'Please, follow the link received via email <0>{{email}}</0> and click on the link to confirm your account',
    },

    AccountActivation: {
      SuccessTitle: 'Account confirmed',
      SuccessDescription: 'Congratulations, you are registered at the system!',
      InvalidTokenTitle: 'Account not confirmed',
      InvalidTokenDescription: 'Sorry, but link is invalid.',
    },

    InvitedSignUp: {
      InvalidTokenTitle: 'Invite not accepted',
      InvalidTokenDescription: 'Sorry, but link is invalid',
    },

    SignIn: {
      Title: 'Log in',
      Labels: {
        Email: 'E-mail',
        Password: 'Password',
        RememberMe: 'Remember me',
      },
      Placeholders: {
        Email: 'Email',
        Password: 'Password',
      },
      ForgotPasswordLink: 'Forgot password?',
      SubmitButton: 'Log in',
      AlternativeAction: "Don't have an account yet? <0>Sign up</0> now",
    },

    ForgotPassword: {
      Title: 'Forgot your password?',
      Description: 'Enter your email address in the form below and we will send you a mail with reset password button',
      Labels: {
        Email: 'E-mail',
      },
      Placeholders: {
        Email: 'Email',
      },
      SubmitButton: 'Continue',
      AlternativeAction: 'Remember password? <0>Log in</0> now',
      SuccessTitle: 'Reset password confirmation',
      SuccessDescription:
        'Please, follow the link received via email <0>{{email}}</0> and click on the link to reset password.',
    },

    RestorePassword: {
      Title: 'Reset your password',
      Description: 'Please, write a new password and then confirm it',
      Labels: {
        Password: 'New password',
        ConfirmPassword: 'Confirm new password',
      },
      Placeholders: {
        Password: 'New password',
        ConfirmPassword: 'Confirm new password',
      },
      SubmitButton: 'Save password',
      SuccessAlert: 'Password has been changed',
    },

    Logout: {
      Button: 'Log out',
      ConfirmationTitle: 'Log out',
      ConfirmationDescription: 'Are you sure you want to log out from the system?',
      ConfirmationButton: 'Log out',
    },
  },

  TermsOfService: {
    Title: 'Terms of Service',
    Welcome: {
      Item1:
        'Welcome to Minerva. Please read these Terms of Service carefully before using the services offered by Minerva Solutions Inc. (“Minerva”, “us”, “we”). Minerva offers a website (the “Site”), AI-powered, fully automated, advertising SaaS platform, and other services, features, content, and applications for real estate professionals (collectively, the “Services”).',
      Item2:
        'This cover sheet and the Terms of Service attached hereto as Exhibit A (collectively, the “Agreement”) set forth the terms and conditions of our relationship and govern your use of the Services (defined below). By signing below or accessing the Services, whichever is earlier, you agree to be bound by the terms of this Agreement, which also incorporate any privacy policy that we may adopt and modify from time to time. This Agreement is effective as of the date you sign below or access the Services, whichever earlier.',
    },
    ExhibitATitle: 'Exhibit A',
    Section1: {
      Title: 'Terms of Service',
      Item1:
        'IMPORTANT NOTICE: THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT, WHICH WILL REQUIRE YOU TO SUBMIT ANY CLAIMS YOU MAY HAVE AGAINST MINERVA TO BINDING AND FINAL ARBITRATION. IN ADDITION, UNDER THE ARBITRATION PROVISION, (A) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST MINERVA ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (B) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO THE SECTIONS REGARDING ARBITRATION.',
      Item2:
        'PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY SIGNING THIS AGREEMENT OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS. If you are not eligible, or do not agree to this Agreement, then you do not have our permission to use the Services.',
    },
    Section2: {
      Title: 'General Agreement between Minerva and You',
      Item1:
        'Minerva may make changes to this Agreement from time to time without specifically notifying you. Minerva will post the latest Agreement on the Site, but it is up to you to review it before using the Services. If you continue to use the Site or Services after any of these changes, your continued use will mean that you have accepted any changes to the Agreement. If you do not accept updated versions of the Services, Minerva shall not bear any responsibility or liability for your decision.',
      Item2:
        'In addition, some services offered through the Services may be subject to additional terms and conditions specified by Minerva from time to time, and your use of such services is subject to those additional terms and conditions, which are incorporated into this Agreement by this reference.',
    },
    Section3: {
      Title: 'Some portions of the Services rely on Third Party Services',
      Item1:
        'You acknowledge that the Services interoperate with several third-party sites and services (collectively, the “Third Party Services”), and that some of the Services provided are dependent on the availability of such Third Party Services. If at any time any Third Party Services cease to provide availability to Minerva, Minerva may cease to provide such features to you without entitling you to refund, credit, or other compensation.',
      Item2:
        'In order to use the features of the Services related to the Third Party Services, you may be required to register for an account with such Third Party Service.',
    },
    Section4: {
      Title: 'What Laws and Rules You Are Responsible for Following',
      Item1:
        'You are responsible for all of your activity in connection with the Services, including abiding by all applicable local, state, national and international laws and regulations applicable to you. You are solely responsible for ensuring that this Agreement complies with all laws, rules and regulations applicable to you. You promise not to use the Site or the Services for any purpose that is prohibited by this Agreement.',
      Item2:
        'You understand Minerva may, in its sole discretion, refuse to offer the Site or the Services to any person or entity and change its eligibility criteria at any time and that Minerva reserves the right (without undertaking any duty) to use “geo-filtering” or other technologies to block access to certain jurisdictions, in its sole discretion, without notice.',
      Item3:
        'You understand that your right to access the Services will be revoked where this Agreement or use of the Site or the Services is prohibited and, if that is the case, you agree not to use or access the Site or the Services in any way.',
      Item4:
        'You agree that Minerva may terminate your use of the Services and prohibit you from accessing the Site and/or the Services, for any reason, or no reason, and with or without notice.',
    },
    Section5: {
      Title:
        'We do our best to keep the Services safe and operational, but we cannot guarantee it. We need your help to do that, which includes the following commitments.',
      Item1:
        'You will not use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site.',
      Item2: 'You will not harvest or scrape any content or materials from the Site and Services.',
      Item3: 'You will not knowingly transmit false or misleading information through the Site or the Services.',
      Item4:
        'You will not use the Site and Services in any manner that: infringes (or results in the infringement of) Minerva’s or any third party’s intellectual property or other rights; is (or you reasonably believe to be) illegal, fraudulent, or unauthorized, or in furtherance of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent activity, or that involves (or you reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent, pirated, or unauthorized material; does not comply with all applicable laws, rules, or regulations, including obtaining all necessary permits, licenses, registrations, etc. In the case of any proposed or actual transaction, “applicable” refers to both your own location and to location(s) of all other parties to the transaction; or would cause Minerva to be in violation of any law or regulation, or to infringe any right of any third party.',
      Item5: 'You will not knowingly transmit false or misleading information through the Site or the Services.',
    },
    Section6: {
      Title: 'We are not responsible for third party sites and content',
      Item1:
        'You understand the Site and the Services may contain (or you may receive from Minerva or third parties) links to other web sites (“Third Party Sites”) or content posted, owned or originating from third parties such as, by way of example only, pictures, designs, photographs, graphics, text, sound, recipes, video, information, software, applications and any other content (“Third Party Content”).',
      Item2:
        'You understand and agree that Minerva is not responsible for, and does not control, Third Party Sites and Third Party Content. You also understand and agree that Minerva is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such Third Party Sites and Third Party Content.',
      Item3:
        'You acknowledge and agree that Minerva shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, goods or services available on or through any such Third Party Site or Third Party Content.',
    },
    Section7: {
      Title: 'Who Owns What and How You Can Use It',
      SubTitle: 'The copyright to all content the on the Site and Services is owned by the provider of that content.',
      Item1:
        'All content and data related to the Services are the property of Minerva and are protected under applicable copyright law, trademark law and other proprietary rights.',
      Item2:
        'You may not copy, redistribute, use or publish any part of the Site or the Services, except as allowed by this Agreement.',
      Item3:
        'You do not acquire ownership rights to any content, document or other materials viewed through the Site or the Services, except as otherwise set forth herein or the terms of an applicable written agreement between us.',
      Item4:
        'If you send to Minerva any information, ideas, inventions, concepts, techniques, or know-how (“User Submissions”), for any purpose, including the developing, manufacturing, and/or marketing or products or services incorporating such information, you acknowledge that Minerva can use such User Submissions without acknowledgement or compensation to you, and you waive any claim of ownership or compensation or other rights you may have in relation to your User Submissions.',
    },
    Section8: {
      Title: 'You may not use trademarks appearing on the Site or Services in an infringing manner',
      Item1:
        'You agree that MINERVA, MINERVA SOLUTIONS, minervasolutions.io, the Minerva logo, and the Minerva graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of Minerva or its affiliates (“Trademark Content”).',
      Item2:
        'Minerva trademarks and trade dress may not be used in connection with any product or service that is not Minerva’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Minerva.',
      Item3:
        'All other trademarks not owned by Minerva or its affiliates that may appear on this Site or the Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Minerva or its affiliates.',
    },
    Section9: {
      Title: 'Legal Conditions',
      SubTitle: 'Minerva’s liability is limited',
      Item1:
        'Minerva is not responsible for any Third Party Sites, Third Party Content, or any other content posted on the Site and the Services, whether caused by Minerva, third parties or by any of the equipment or programming associated with or utilized in the Site and the Services.',
      Item2:
        'Minerva assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of communications.',
      Item3:
        'Minerva is not responsible for any problems or technical malfunction of any telephone network or lines, cellular data provider or network, computer online systems, servers or providers, computer equipment, software, failure of email on account of technical problems or traffic congestion on the Services or Site, including injury or damage to any other person’s computer and/or mobile device.',
      Item4:
        'Neither Minerva nor any of its affiliates, advertisers, promoters or distribution partners shall be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of the Site or the Services, whether online or offline.',
    },
  },

  PrivacyPolicy: {
    Title: 'Privacy Policy',
    SubTitle: 'Last updated: June 03, 2022',
    Note1:
      'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    Note2:
      'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.',
    Section1: {
      Title: 'Interpretation and Definitions',
      SubTitle: 'Interpretation',
      Item1:
        'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    },
    Section2: {
      Title: 'Definitions',
      SubTitle: 'For the purposes of this Privacy Policy:',
      Item1:
        '<strong>Account</strong> means the software program provided by the Company downloaded by You on any electronic device, named Minerva.',
      Item2:
        '<strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
      Item3:
        '<strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Minerva',
      Item4:
        "<strong>Business</strong>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.",
      Item5:
        '<strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Minerva Solutions Inc., 333 Hudston St. Ste. 503 New York, NY 10014. For the purpose of the GDPR, the Company is the Data Controller.',
      Item6:
        '<strong>Consumer</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.',
      Item7: '<strong>Country</strong> refers to: New York, United States',
      Item8:
        '<strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
      Item9:
        '<strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.',
      Item10:
        '<strong>Facebook Fan Page</strong> is a public profile named Minerva Solutions specifically created by the Company on the Facebook social network, accessible from <0>{{link}}</0>',
      Item11:
        '<strong>Personal Data</strong> is any information that relates to an identified or identifiable individual. For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.',
      Item12:
        "<strong>Sale</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal information to another business or a third party for monetary or other valuable consideration.",
      Item13: '<strong>Service</strong>  refers to the Application.',
      Item14:
        '<strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.',
      Item15:
        '<strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.',
      Item16:
        '<strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
      Item17:
        '<strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable',
    },
    Section3: {
      Title: 'Collecting and Using Your Personal Data',
      SubTitle1: 'Types of Data Collected',
      SubTitle2: 'Personal Data',
      Desc1:
        'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
      Item1: 'Email address',
      Item2: 'First name and last name',
      Item3: 'Phone number',
      Item4: 'Address, State, Province, ZIP/Postal code, City',
      Item5: 'Bank account information in order to pay for products and/or services within the Service',
      Item6: 'Usage Data',
      Desc2:
        'When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:',
      Item7: 'Date of birth',
      Item8: 'Passport or National ID card',
      Item9: 'Bank card statement',
      Item10: 'Other information linking You to an address',
      SubTitle3: 'Usage Data',
      Item11: 'Usage Data is collected automatically when using the Service.',
      Item12:
        "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
      Item13:
        'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
      Item14:
        'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
      SubTitle4: 'Information from Third-Party Social Media Services',
      Desc3:
        'The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:',
      Item15: 'Google',
      Item16: 'Facebook',
      Item17: 'Twitter',
      Item18: 'LinkedIn',
      Item19:
        "If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your account IDs, Your activities, Your account performance data or Your contact list associated with that account",
      Item20:
        "You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.",
    },
    Section4: {
      Title: 'Use of Your Personal Data',
      Desc1: 'The Company may use Personal Data for the following purposes:',
      Item1: '<strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.',
      Item2:
        '<strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
      Item3:
        '<strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
      Item4:
        "<strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
      Item5:
        '<strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
      Item6: '<strong>To manage Your requests:</strong> To attend and manage Your requests to Us.',
      Item7:
        '<strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
      Item8:
        '<strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
      Desc2: 'We may share Your personal information in the following situations:',
      Item9:
        '<strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You.',
      Item10:
        '<strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
      Item11:
        '<strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
      Item12:
        '<strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.',
      Item13:
        '<strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.',
      Item14:
        '<strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.',
    },
    Section5: {
      Title: 'Retention of Your Personal Data',
      Item1:
        'You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.',
      Item2:
        'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    },
    Section6: {
      Title: 'Transfer of Your Personal Data',
      Item1:
        "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
      Item2:
        'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
      Item3:
        'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    },
    Section7: {
      Title: 'Disclosure of Your Personal Data',
      SubTitle1: 'Business Transactions',
      Item1:
        'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
      SubTitle2: 'Law enforcement',
      Item2:
        'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
      SubTitle3: 'Other legal requirements',
      Desc: 'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
      Item3: 'Comply with a legal obligation',
      Item4: 'Protect and defend the rights or property of the Company',
      Item5: 'Prevent or investigate possible wrongdoing in connection with the Service',
      Item6: 'Protect the personal safety of Users of the Service or the public',
      Item7: 'Protect against legal liability',
    },
    Section8: {
      Title: 'Security of Your Personal Data',
      Item: 'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    },
    Section9: {
      Title: 'Detailed Information on the Processing of Your Personal Data',
      Item: 'The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.',
    },
    Section10: {
      Title: 'Analytics',
      Desc: 'Google Analytics',
      Item1:
        'Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.',
      Item2:
        'You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <0>{{link}}</0>',
      Item3:
        'For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <0>{{link}}</0>',
      Item4: 'We may use third-party Service providers to monitor and analyze the use of our Service.',
    },
    Section11: {
      Title: 'Payments',
      Item1:
        'We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).',
      Item2:
        'We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.',
      Item3: '<strong>Stripe</strong> Their Privacy Policy can be viewed at <0>{{link}}</0>',
      Item4: '<strong>PayPal</strong> Their Privacy Policy can be viewed at <0>{{link}}</0>',
      Item5:
        'When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.',
    },
    Section12: {
      Title: 'GDPR Privacy',
      SubTitle: 'Legal Basis for Processing Personal Data under GDPR',
      Item1: 'We may process Personal Data under the following conditions:',
      Item2:
        '<strong>Consent:</strong> You have given Your consent for processing Personal Data for one or more specific purposes.',
      Item3:
        '<strong>Performance of a contract:</strong> Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.',
      Item4:
        '<strong>Legal obligations:</strong> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
      Item5:
        '<strong>Vital interests:</strong> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
      Item6:
        '<strong>Public interests:</strong> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
      Item7:
        '<strong>Legitimate interests:</strong> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
      Item8:
        'In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.',
    },
    Section13: {
      Title: 'Your Rights under the GDPR',
      Item1:
        'The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.',
      Item2: 'You have the right under this Privacy Policy, and by law if You are within the EU, to:',
      Item3:
        '<strong>Request access to Your Personal Data.</strong> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.',
      Item4:
        '<strong>Request correction of the Personal Data that We hold about You.</strong> You have the right to have any incomplete or inaccurate information We hold about You corrected.',
      Item5:
        '<strong>Object to processing of Your Personal Data.</strong> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.',
      Item6:
        '<strong>Request erasure of Your Personal Data.</strong> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
      Item7:
        '<strong>Request the transfer of Your Personal Data.</strong> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.',
      Item8:
        '<strong>Withdraw Your consent.</strong> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.',
    },
    Section14: {
      Title: 'Exercising of Your GDPR Data Protection Rights',
      Item1:
        'You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.',
      Item2:
        'You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.',
    },
    Section15: {
      Title: 'Facebook Fan Page',
      SubTitle: 'Data Controller for the Facebook Fan Page',
      Item1:
        'The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page  <0>{{link}}</0>, the Company and the operator of the social network Facebook are Joint Controllers.',
      Item2:
        'The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service: <0>{{link}}</0>',
      Item3:
        'Visit the Facebook Privacy Policy <0>{{link}}</0> for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.',
    },
    Section16: {
      Title: 'Facebook Insights',
      Item1:
        'We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.',
      Item2:
        'For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.',
      Item3:
        'Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services.',
      Item4:
        'For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here: <0>{{link}}</0>',
    },
    Section17: {
      Title: 'CCPA Privacy',
      Item: 'This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.',
    },
    Section18: {
      Title: 'Categories of Personal Information Collected',
      Item1:
        'We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.',
      Item2:
        'Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.',
      Item3:
        "<strong>Category A: Identifiers.</strong> Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers. Collected: Yes.",
      Item4:
        "<strong>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</strong> Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories. Collected: Yes.",
      Item5:
        '<strong>Category C: Protected classification characteristics under California or federal law.</strong> Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information). Collected: No.',
      Item6:
        '<strong>Category D: Commercial information.</strong> Examples: Records and history of products or services purchased or considered. Collected: Yes.',
      Item7:
        '<strong>Category E: Biometric information.</strong> Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data. Collected: No.',
      Item9:
        '<strong>Category F: Internet or other similar network activity.</strong> Examples: Interaction with our Service or advertisement. Collected: Yes.',
      Item10: '<strong>Category G: Geolocation data.</strong> Examples: Approximate physical location. Collected: No.',
      Item11:
        '<strong>Category H: Sensory data.</strong> Examples: Audio, electronic, visual, thermal, olfactory, or similar information. Collected: No.',
      Item12:
        '<strong>Category I: Professional or employment-related information.</strong> Examples: Current or past job history or performance evaluations. Collected: No.',
      Item13:
        '<strong>Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</strong> Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records. Collected: No.',
      Item14:
        "<strong>Category K: Inferences drawn from other personal information.</strong> Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes. Collected: No.",
      Item15: 'Under CCPA, personal information does not include:',
      Item16: 'Publicly available information from government records',
      Item17: 'Deidentified or aggregated consumer information',
      Item18: "Information excluded from the CCPA's scope, such as:",
      Item19:
        'Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data',
      Item20:
        "Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994",
    },
    Section19: {
      Title: 'Sources of Personal Information',
      Item1: 'We obtain the categories of personal information listed above from the following categories of sources:',
      Item2:
        '<strong>Directly from You.</strong> For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service.',
      Item3: '<strong>Indirectly from You.</strong> For example, from observing Your activity on our Service.',
      Item4:
        '<strong>Automatically from You.</strong> For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.',
      Item5:
        '<strong>From Service Providers.</strong>  For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You.',
    },
    Section20: {
      Title: 'Use of Personal Information for Business Purposes or Commercial Purposes',
      Item1:
        'We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:',
      Item2: 'To operate our Service and provide You with our Service.',
      Item3:
        'To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.',
      Item4:
        'To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.',
      Item5:
        'To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.',
      Item6: 'As described to You when collecting Your personal information or as otherwise set forth in the CCPA.',
      Item7: 'For internal administrative and auditing purposes.',
      Item8:
        'To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.',
      Item9:
        'Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section.',
      Item10:
        'If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.',
    },
    Section21: {
      Title: 'Disclosure of Personal Information for Business Purposes or Commercial Purposes',
      Item1:
        'We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:',
      Item2: 'Category A: Identifiers',
      Item3:
        'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))',
      Item4: 'Category D: Commercial information',
      Item5: 'Category F: Internet or other similar network activity',
      Item6:
        'Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.',
      Item7:
        'When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.',
    },
    Section22: {
      Title: 'Sale of Personal Information',
      Item1:
        'As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer\'s personal information by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.',
      Item2:
        'Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been shared for value in return.',
      Item3:
        'We may sell and may have sold in the last twelve (12) months the following categories of personal information:',
      Item4: 'Category A: Identifiers',
      Item5:
        'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))',
      Item6: 'Category D: Commercial information',
      Item7: 'Category F: Internet or other similar network activity',
    },
    Section23: {
      Title: 'Share of Personal Information',
      Item1:
        'We may share Your personal information identified in the above categories with the following categories of third parties:',
      Item2: 'Service Providers',
      Item3: 'Payment processors',
      Item4: 'Our affiliates',
      Item5: 'Our business partners',
      Item6:
        'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You',
    },
    Section24: {
      Title: 'Sale of Personal Information of Minors Under 16 Years of Age',
      Item1:
        "We do not knowingly collect personal information from minors under the age of 16 through our Service, although certain third party websites that we link to may do so. These third-party websites have their own terms of use and privacy policies and we encourage parents and legal guardians to monitor their children's Internet usage and instruct their children to never provide information on other websites without their permission.",
      Item2:
        'We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.',
      Item3:
        'If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.',
    },
    Section25: {
      Title: 'Your Rights under the CCPA',
      Item1:
        'The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:',
      Item2:
        '<strong>The right to notice.</strong> You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.',
      Item3:
        '<strong>The right to request.</strong> Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:',
      Item4: 'The categories of personal information We collected about You',
      Item5: 'The categories of sources for the personal information We collected about You',
      Item6: 'Our business or commercial purpose for collecting or selling that personal information',
      Item7: 'The categories of third parties with whom We share that personal information',
      Item8: 'The specific pieces of personal information We collected about You',
      Item10:
        'If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:',
      Item11: 'The categories of personal information categories sold',
      Item12: 'The categories of personal information categories disclosed',
      Item13:
        '<strong>The right to say no to the sale of Personal Data (opt-out).</strong> You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us.',
      Item14:
        '<strong>The right to delete Personal Data.</strong>  You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:',
      Item15:
        'Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.',
      Item16:
        'Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.',
      Item17: 'Debug products to identify and repair errors that impair existing intended functionality.',
      Item18:
        'Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.',
      Item19: 'Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).',
      Item20:
        "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.",
      Item21:
        'Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.',
      Item22: 'Comply with a legal obligation.',
      Item23:
        'Make other internal and lawful uses of that information that are compatible with the context in which You provided it.',
      Item24:
        "<strong>The right not to be discriminated against.</strong> The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:",
      Item25: 'Denying goods or services to You',
      Item26:
        'Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties',
      Item27: 'Providing a different level or quality of goods or services to You',
      Item28:
        'Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services',
    },
    Section26: {
      Title: 'Exercising Your CCPA Data Protection Rights',
      Item1:
        'In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:',
      Item2: 'By email: <strong>{{supportEmail}}</strong>',
      Item3:
        'Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information.',
      Item4:
        'Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative',
      Item5:
        'Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it',
      Item6: 'We cannot respond to Your request or provide You with the required information if we cannot:',
      Item7: 'Verify Your identity or authority to make the request',
      Item8: 'And confirm that the personal information relates to You',
      Item9:
        'We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.',
      Item10:
        "Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.",
      Item11:
        'For data portability requests, We will select a format to provide Your personal information that is readily usable and should allow You to transmit the information from one entity to another entity without hindrance.',
      Item12: 'Your request to Us must:',
    },
    Section27: {
      Title: 'Do Not Sell My Personal Information',
      Item1:
        'You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.',
      Item2:
        'The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.',
      Item3:
        'Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use.',
      SubTitle1: 'Website',
      Item4:
        'You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:',
      Item5: "The NAI's opt-out platform: <0>{{link}}</0>",
      Item6: "The EDAA's opt-out platform <0>{{link}}</0>",
      Item7: "The DAA's opt-out platform: <0>{{link}}</0>",
      Item8:
        'The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, You will need to opt out again.',
      SubTitle2: 'Mobile Devices',
      Item9:
        'Your mobile device may give You the ability to opt out of the use of information about the apps You use in order to serve You ads that are targeted to Your interests:',
      Item10: '"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices',
      Item11: '"Limit Ad Tracking" on iOS devices',
      Item12:
        'You can also stop the collection of location information from Your mobile device by changing the preferences on Your mobile device.',
    },
    Section28: {
      Title: '"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)',
      Item1: 'Our Service does not respond to Do Not Track signals.',
      Item2:
        'However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.',
    },
    Section29: {
      Title: "Children's Privacy",
      Item1:
        'Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.',
      Item2:
        "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    },
    Section30: {
      Title: "Your California Privacy Rights (California's Shine the Light law)",
      Item1:
        "Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.",
      Item2:
        "If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.",
    },
    Section31: {
      Title: 'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)',
      Item1:
        'California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.',
      Item2:
        'To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.',
      Item3:
        'Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.',
    },
    Section32: {
      Title: 'Links to Other Websites',
      Item1:
        "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
      Item2:
        'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    },
    Section33: {
      Title: 'Changes to this Privacy Policy',
      Item1:
        'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
      Item2:
        'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
      Item3:
        'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
    },
    Section34: {
      Title: 'Data Removal',
      Item1:
        'Users wishing their data to be deleted with no conditions may submit their request via email at <strong>{{supportEmail}}</strong>',
    },
    Section35: {
      Title: 'Your Feedback to Us',
      Item1:
        'You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.',
    },
    Section36: {
      Title: 'Contact Us',
      Item1:
        'If you have any questions about these Terms and Conditions, You can contact us by email: <strong>{{supportEmail}}</strong>',
    },
  },

  FrequentlyAskedQuestion: {
    Title: 'F.A.Q.',
    Question1: {
      Title: 'How to connect Facebook?',
      Item: {
        Step1: {
          Title: 'STEP 1',
          Description: 'Click on the “Connect” button for Facebook on the “Connect platform” popup',
        },
        Step2: {
          Title: 'STEP 2',
          Description: 'Log into your Facebook account',
        },
        Step3: {
          Title: 'STEP 3',
          Description: 'Click on the “Choose what you allow” button',
        },
        Step4: {
          Title: 'STEP 4',
          Description: 'Check that you provide all needed permissions:',
          Item1: 'Manage ads for ad accounts that you have access to',
          Item2: 'Access your Facebook ads and related stats',
          Item3: 'Manage your business (Select All)',
          Item4: 'Access profile and posts from the Instagram account connected to your Page (Select All)',
          Item5: 'Upload media and create posts for the Instagram account connected to your Page',
          Item6: 'Access insights for the Instagram account connected to your Page (Select All)',
          Item7: 'Access your Page and App insights',
          Item8: 'Access leads for your Pages (Select All)',
          Item9: 'Create and manage content on your Page (Select All)',
          Item10: 'Read content posted on the Page (Select All)',
          Item11: 'Create and manage ads for your Page (Select All)',
          Item12: 'Show a list of the Pages you manage (Select All)',
        },
        Step5: {
          Title: 'STEP 5',
          Description: 'Click on the “Continue” button',
        },
        Step6: {
          Title: 'STEP 6',
          Description: 'Choose the needed Ad account and click on the “Connect” button',
        },
        Step7: {
          Title: 'STEP 7',
          Description: 'Congratulations! Your account is connected',
        },
      },
    },
    Question2: {
      Title: 'Questions 2',
      Item: 'TBD',
    },
    Question3: {
      Title: 'Questions 3',
      Item: 'TBD',
    },
    Question4: {
      Title: 'Questions 4',
      Item: 'TBD',
    },
    Question5: {
      Title: 'Questions 5',
      Item: 'TBD',
    },
    Question6: {
      Title: 'Questions 6',
      Item: 'TBD',
    },
    Question7: {
      Title: 'Questions 7',
      Item: 'TBD',
    },
    Question8: {
      Title: 'Questions 8',
      Item: 'TBD',
    },
    Question9: {
      Title: 'Questions 9',
      Item: 'TBD',
    },
    Question10: {
      Title: 'Questions 10',
      Item: 'TBD',
    },
  },

  OrganizationSetup: {
    Title: 'Organization creating',
    Description: '{{name}}, please choose your role and name your organization',
    Labels: {
      Name: 'Organization name',
      State: 'State',
      City: 'City',
      Phone: 'Leasing office phone number',
    },
    Placeholders: {
      Name: 'Organization name',
      State: 'State',
      City: 'City',
      Phone: 'Leasing office phone number',
    },
    Alerts: {
      Created: 'Organization created',
    },
    SubmitButton: 'Create organization',
    SubmitConfirmation: {
      Title: 'Create organization',
      Description: 'Are you sure you want to create organization? You cannot change the organization in the future.',
      ConfirmButton: 'Create organization',
    },
  },
  Navigation: {
    Labels: {
      Dashboard: 'Dashboard',
      Profile: 'Profile',
      Campaigns: 'Campaigns',
      BrandingCampaigns: 'Branding Campaigns',
      ListingCampaigns: 'Listing Campaigns',
      Buildings: 'Buildings',
      Listings: 'Listings',
      Reports: 'Reports',
      Details: 'Organization',
      MarketingDescription: 'Marketing Description',
      CreativeIntelligence: 'Creative Intelligence',
      CreativeManagement: 'Creative Management',
    },
  },
  Footer: {
    Title: `\u00A9 ${new Date().getFullYear()}. Minerva`,
  },
  Tables: {
    Footer: {
      Total: '{{count}} record',
      Total_plural: '{{count}} records',
      SeeAll: 'See All',
    },
  },
  Statuses: {
    Campaigns: {
      Live: 'Live',
      Paused: 'Paused',
      Finished: 'Finish',
      Created: 'Created',
      Published: 'Published',
      Draft: 'Draft',
      Close: 'Closed',
    },
    Invites: {
      Sent: 'Invite sent',
      Accepted: 'Invite accepted',
      Declined: 'Invite declined',
    },
  },
  FormFooter: {
    SaveButton: 'Save',
    CancelButton: 'Cancel',
  },
  GeoTargetingField: {
    Creator: {
      CreateButton: 'Create Additional Geotarget',
      Modal: {
        Title: 'Create new geotarget',
        Labels: {
          State: 'State',
          City: 'City',
        },
        Placeholders: {
          State: 'Select state',
          City: 'Select city',
        },
        SubmitButton: 'Create geotarget',
      },
    },
    Tooltip:
      'We must target your {{typeLabel}}’s location to comply with Fair Housing so you may not remove the location from the geotargeting settings. However, you may target as many additional locations based on where your tenants move from.',
  },
  ApartmentParametersField: {
    Labels: {
      ApartmentPrices: 'Apartament price range',
      ApartmentParameters: 'Unit specifications',
      MinPrice: 'Minimum price',
      MaxPrice: 'Maximum price',
    },
    Placeholders: {
      ApartmentParameters: 'Select unit specifications',
      MinPrice: 'Minimum price',
      MaxPrice: 'Maximum price',
    },
    AddButton: 'Add unit specifications',
  },

  BuildingForm: {
    SyncWithYardi: 'Sync with Yardi',
    YardiIdPlaceholder: 'Yardi Property Id',
    YardiIdTitle: 'Yardi Property Id',
    TooltipRegenerationInfo:
      'To maximize ad performance, these headlines must be used exactly as provided and cannot be altered',
    Processing: 'Your building is still being created, you cannot edit it, please wait.',
    Error: 'An error occurred while creating the building.',
    NewBuildingTitle: 'Create building',
    NewBuildingTitleTooltip:
      'The "Create building" page allows the user to create a new building in order to create a building campaign on a building basis',
    EditBuildingTitle: 'Edit building',
    EditBuildingTitleTooltip:
      'The "Edit building" page allows the user to edit an existing building in order to create a building campaign on a building basis or update existing building campaign',
    Labels: {
      Name: 'Building name',
      State: 'State',
      City: 'City',
      Zip: 'ZIP',
      Neighborhood: 'Neighborhood, subdivision or development',
      Address: 'Address',
      Url: 'URL',
      PropertyType: 'Property type',
      Amenities: 'Building amenities',
      Competitors: 'Competitors',
      Masstransit: 'Mass transit',
      Description: 'Description',
      Pictures: 'Building pictures',
    },
    Placeholders: {
      Name: 'Building name',
      State: 'Select state',
      City: 'Select city',
      Zip: 'ZIP',
      Neighborhood: 'Neighborhood, subdivision or development',
      Address: 'Address',
      Url: 'URL',
      PropertyType: 'Select property type',
      Amenities: 'Select building amenities',
      Masstransit: 'Select mass transit',
      Competitors: 'Select competitors',
      Description: 'Description',
    },
    CreateButton: 'Save',
    CreateAndContinueButton: 'Save and Create another',
    DeleteButton: 'Delete',
    EditButton: 'Save',
    DeleteConfirmation: {
      Title: 'Delete building',
      Description: 'Are you sure you want to delete the building?',
      ConfirmButton: 'Delete',
    },
    Alerts: {
      Created: 'Building created',
      Edited: 'Building edited',
      Deleted: 'Building deleted',
      AffectedCampaignsWarning:
        'Due to changes in the building, some of your campaigns have been updated. Please check the highlighted campaigns',
    },
  },

  ListingForm: {
    Processing: 'Your listing is still being created, you cannot edit it, please wait.',
    Error: 'An error occurred while creating the listing.',
    NewListingTitle: 'Create listing',
    NewListingTitleTooltip:
      'The "Create listing" page allows the user to create a new listing in order to create a listing campaign on a listing basis',
    EditListingTitle: 'Edit listing',
    EditListingTitleTooltip:
      'The "Edit listing" page allows the user to edit an existing listing in order to create a listing campaign on a listing basis or update existing listing campaign',
    Labels: {
      Name: 'Listing name',
      Agent: 'Listing agent',
      State: 'State',
      City: 'City',
      Zip: 'ZIP',
      Neighborhood: 'Neighborhood, subdivision or development',
      Address: 'Address',
      Url: 'URL',
      PropertyType: 'Property type',
      ListingType: 'Listing type',
      MonthlyRent: 'Monthly rent',
      Price: 'Price',
      Amenities: 'Listing amenities',
      Masstransit: 'Mass transit',
      Bedrooms: 'Bedrooms',
      Bathrooms: 'Bathrooms',
      Acres: 'Acres',
      SquareFeet: 'Square Feet',
      Description: 'Description',
      Pictures: 'Listing pictures',
    },
    Placeholders: {
      Name: 'Listing name',
      Agent: 'Listing agent',
      State: 'Select state',
      City: 'Select city',
      Zip: 'ZIP',
      Neighborhood: 'Neighborhood, subdivision or development',
      Address: 'Address',
      Url: 'URL',
      PropertyType: 'Select property type',
      MonthlyRent: 'Monthly rent',
      Price: 'Price',
      Amenities: 'Select listing amenities',
      Masstransit: 'Select listing mass transit',
      Bedrooms: 'Bedrooms',
      Bathrooms: 'Bathrooms',
      Acres: 'Acres',
      SquareFeet: 'Square Feet',
      Description: 'Description',
    },
    CreateButton: 'Save',
    CreateAndContinueButton: 'Save and Create another',
    DeleteButton: 'Delete',
    EditButton: 'Save',
    DeleteConfirmation: {
      Title: 'Delete listing',
      Description: 'Are you sure you want to delete the listing?',
      ConfirmButton: 'Delete',
    },
    Alerts: {
      Created: 'Listing created',
      Edited: 'Listing edited',
      Deleted: 'Listing deleted',
      AffectedCampaignsWarning:
        'Due to changes in the listing, some of your campaigns have been updated. Please check the highlighted campaigns',
    },
  },

  CampaignForm: {
    Info: {
      Title: 'Campaign info',
      TitleTooltip: 'The "Campaign info" block allows the user to fill in basic information about a campaign',
      Labels: {
        CampaignType: 'Campaign type',
        CampaignStatus: 'Campaign status',
        SocialNetworks: 'Platforms',
        Building: 'Building',
        Listing: 'Listing:',
        BuildingTargetLink: 'View',
        ListingTargetLink: 'View',
        CampaignName: 'Campaign name',
        Property: 'Property',
        OpenHouseRunDates: 'Date of open house',
        BrandingCampaignType: 'Branding campaign type',
        FacebookPage: 'Facebook',
        FacebookPixel: 'Pixel',
        FacebookCustomAudience: 'Custom',
        InstagramAccount: 'Instagram',
        GoogleAccount: 'Google',
      },
      Placeholders: {
        CampaignName: 'Campaign name',
        Building: 'Select building',
        Listing: 'Select listing',
        FacebookPage: 'Facebook',
        FacebookPixel: 'Pixel',
        FacebookCustomAudience: 'Custom',
        InstagramAccount: 'Instagram',
        GoogleAccount: 'Google',
      },
      AddBuildingButton: 'Add Building',
      AddListingButton: 'Add Listing',
    },
    Details: {
      Title: 'Schedule and budgets',
      TitleTooltip: 'The "Schedule and budgets" block allows the user to fill in time and financial information',
      Labels: {
        RunDates: 'Run Dates',
        RunDate: 'Run Date',
      },
      Budget: {
        FbDailyBudget: {
          Label: 'Facebook/Instagram budget',
          Placeholder: 'min $10',
          Tooltip:
            'Facebook & Instagram daily budget is auto-optimized through Meta AdManager for FB/IG for Traffic, Conversion, and Retargeting Campaigns',
        },
        FbMonthlyBudget: {
          Label: 'Facebook monthly budget (min $300)',
          Placeholder: 'min $300',
          Tooltip:
            'Facebook & Instagram monthly budget is auto-optimized through Meta AdManager for FB/IG for Traffic, Conversion, and Retargeting Campaigns',
        },
        AdsDailyBudget: {
          Label: 'Display budget',
          Placeholder: 'min $2',
          Tooltip: 'Display ads are used for retargeting across the web using the Google Display Network',
        },
        AdsMonthlyBudget: {
          Label: 'Display monthly budget (min $60)',
          Placeholder: 'min $60',
          Tooltip: 'Display ads are used for retargeting across the web using the Google Display Network',
        },
        AdWordsDailyBudget: {
          Label: 'Google search budget',
          Placeholder: 'min $10',
          Tooltip: 'Budget is optimized for both Branded and Non-Branded search campaigns',
        },
        AdWordsMonthlyBudget: {
          Label: 'Google search monthly budget (min $300)',
          Placeholder: 'min $300',
          Tooltip: 'Budget is optimized for both Branded and Non-Branded search campaigns',
        },
      },
    },
    GeographicTargeting: {
      Title: 'Target locations',
      TitleTooltip: 'The "Target locations" block allows the user to choose in which cities the system will show ads',
      Location: '{{typeLabel}} location',
    },
    AdPreview: {
      Title: 'Ads preview',
      TitleTooltip:
        'The "Ads preview" block allows the user to select a title and description for the ad generated by Minerva, select images, and preview how the ad will look',
      Facebook: 'Facebook',
      Instagram: 'Instagram',
      GoogleAdwords: 'Google search',
      GoogleAds: 'Display',
      FacebookPreview: {
        Title: 'Minerva',
        Description: 'Sponsored',
        LearnMore: 'Learn more',
        Like: 'Like',
        Comment: 'Comment',
        Share: 'Share',
      },
      InstagramPreview: {
        Title: 'minerva',
      },
      Placeholder: {
        Title: 'Ads preview unavailable',
        EmptyTabsDescription: 'Please choose platform',
        BuildingDescription: 'Please, choose building to see ads preview',
        ListingDescription: 'Please, choose listing to see ads preview',
        BrandingAbsentTargetDescription: 'Please choose branding campaign type to see ads preview',
        BrandingInvalidTargetDescription: 'Please fill all needed information to the profile or organization',
      },
      GoogleAdsPreview: {
        ExampleAdImage160x600: 'Example of your image ad at 160x600',
        ExampleAdImage300x250: 'Example of your image ad at 300x250',
        ExampleAdText: 'Example of your text ad at 300x250',
        ExampleAdNativeImage480x120: 'Example of your native ad at 480x120',
        ExampleAdNativeImage373x160: 'Example of your native ad at 373x160',
        Title: 'Minerva',
        Open: 'Open',
        Options: {
          KeyAdFormats: 'Key ad formats',
          ImageAds: 'Image ads',
          TextAds: 'Text ads',
          NativeAds: 'Native ads',
        },
      },
      GoogleAdwordsAdPreview: {
        Keywords: 'Keywords',
      },
    },
    Footer: {
      GoLiveButton: 'Go live',
      SaveDraftButton: 'Save as draft',
      DeleteButton: 'Delete campaign',
      PauseButton: 'Pause',
      SaveButton: 'Save',
      EditButton: 'Update',
      DeleteConfirmation: {
        Title: 'Delete campaign',
        Description: 'Are you sure you want to delete the campaign?',
        ConfirmButton: 'Delete',
      },
      CopyButton: 'Make copy',
      CopyConfirmation: {
        Title: 'Make campaign copy',
        Description: 'This campaign has expired and is closed. You can copy this campaign and start it.',
        ConfirmButton: 'Make copy',
      },
    },
    Alerts: {
      Created: 'Campaign created',
      Updated: 'Campaign updated',
      Removed: 'Campaign removed',
      Paused: 'Campaign stopped',
      GoLive: 'Campaign Live',
      Copied: 'Campaign copied',
    },
    Warnings: {
      ABRunning:
        'A/B testing has been started for this campaign. You can not edit/delete the campaign while A/B testing is active.',
      TextsUpdated: 'Titles and descriptions were updated! Press the Edit button to see changes',
      ImagesUpdated: 'Images were updated! Press the Image Settings button to see changes',
      TextIsClosed: 'This campaign has expired and is closed. You cannot edit a closed campaign.',
      TextIsChangedLocation: '{{typeLabel}} location was updated!',
      GoogleTokenExpired: 'Your Google token is expired. Please disconnect the Google platform and connect it again',
      GoogleAccountUnavailable:
        'Your Google account permissions are wrong. Please set up correct permissions in your Google account and refresh the page',
      GoogleCallInvalidAgent:
        'Google has flagged your phone number as invalid. Please update the phone number used on your Individual Profile Page.',
      GoogleCallInvalid:
        'Google has flagged your phone number as invalid. Please update the phone number used on your Organization’s Profile Page.',
      KeywordsUpdated: 'Keywords were updated! Press the Keywords button to see changes',
      BuildingUpdated:
        'The building has been updated! Сheck the settings of images, titles, descriptions and keywords to see changes.',
    },
    Errors: {
      AbsentProfileWebsite: 'Link missing! Fill in the information on the "Profile" page in the "Contact info" block',
      AbsentProfileImages:
        'Images missing! Fill in the information on the "Profile" page in the "Profile photos" block',
      AbsentOrganizationWebsite:
        'Link missing! Fill in the information on the "Organization" page in the "Organization info" block',
      AbsentOrganizationImages:
        'Images missing! Fill in the information on the "Organization" page in the "Organization photos" block',
      AbsentKeywords: 'AdGroups and keywords are missing! Please, add at least one AdGroup and one keyword!',
      DuplicatedTitles: 'Titles are duplicated! Please, change the titles so that they are not the same!',
      DuplicatedDescriptions:
        'Descriptions are duplicated! Please, change the descriptions so that they are not the same!',
      EmptyDescriptionGoogleAds: 'Description is missing! Please, fill in the description for the Display campaign!',
      EmptyDescriptionGoogleAdWords:
        'One or more descriptions are missing! Please, fill in all descriptions for the Google search campaign!',
      EmptyDescriptionFBInstagram: 'Description is missing! Please fill in the description.',
      EmptyTitle: 'One or more titles are missing! Please fill in all the required titles.',
      MaxLengthForDescriptionGoogle: `Please shorten this description to 90 characters to comply with google's requirements`,
      MaxLengthForTitleGoogle: `Please shorten this title to 30 characters to comply with google's requirements`,
      ExclamationMarksGoogleTitle: `Exclamation marks are not allowed in titles`,
      MaxLengthForDescriptionFb: `Please shorten this description to 350 characters to comply with facebook requirements`,
      MaxLengthForTitleFb: `Please shorten this title to 50 characters to comply with facebook requirements`,
    },
    GoogleExpiredTokenConfirmation: {
      Description: 'Your Google token is expired. Please select one of the options:',
      CancelButton: 'Skip and proceed without Google',
      ConfirmButtonButton: 'Update the Google account',
    },
    CampaignABTesting: {
      Create: {
        Title: 'Create your A/B test',
        SubTitle:
          'To execute an A/B test Minerva will need you to create two ads for the same campaign. These ads will be identical except for one variable changed between them (eg. the image or copy). Your budget will be split between these two versions and the ads will be served to the same audience. Minerva will analyze the performance and will determine a winner when the test is complete.',
        VariantA: 'Variant A',
        VariantB: 'Variant B',
        Start: 'Start A/B Testing',
        Skip: 'Cancel',
        SkipThisRecommendation: 'Skip this recommendation next time',
        Alerts: {
          Created: 'A/B testing started',
        },
      },
      Preview: {
        ABTest: 'A/B Test',
        Off: 'OFF',
        On: 'ON',
        VariantA: 'Var. A',
        VariantB: 'Var. B',
        Table: {
          Title: 'A/B Testing',
          Columns: {
            MagicKPI: 'Magic KPI',
            Impressions: 'Impressions',
            Clicks: 'Clicks',
          },
        },
      },
      ModalCanNotStarted: {
        Title: 'A/B test cannot be started',
        SubTitle1: 'Please make sure that the following requirements are met',
        SubTitle2: 'Please update the campaign and make sure all the unsaved changes are saved.',
        SubTitle3: 'Variant A and Variant B cannot be the same. Please change one part of Variant B',
        Items: {
          Item1: 'the FB/Instagram daily budget is not less than 20$',
          Item2: 'there are six or more days left in the duration of the campaign',
          Item3: 'the campaign is still set to “Live” status',
        },
        Button: 'OK',
      },
      ModalABTestWarning: {
        Title: 'A/B Test Warning',
        SubTitle1: 'A/B testing must be held for a minimum of 6 days. Would you like to continue?',
        SubTitle2: 'A/B has a minimum campaign budget of $20 per day. Do you want to stop A/B testing?',
        SubTitle3: 'You cannot edit the ad because A/B testing has already started. Do you want to stop A/B testing?',
        Button: 'Stop A/B test',
      },
      ModalSelectAd: {
        Title: 'Please check out with ad to start promoting',
        Button: 'Confirm',
      },
    },
  },

  ImageUploader: {
    Placeholder: 'Drop or Select file',
    RequirementsSquare: 'Minimal Display square image size 300x300 px',
    RequirementsLandscape: 'Minimal Display landscape image size 600x314 px',
    AmountLimitExceededError: `You can upload maximum ${imageUploader.amountLimit} files`,
    WrongExtensionError: 'File should has one of these extension: {{extensions}}',
    FileSizeLimitExceededError: `Sorry! We can’t upload this image. It should have up to ${bytes(
      imageUploader.fileSizeLimit,
    )}`,
    NoPhoto: 'No Photo',
    Tooltip:
      'To run campaigns on Google and Meta, we need images in both 1:1 and 1.91:1 image ratio as they are required by the platforms. Please crop images in both of these formats using our tool to make sure you can run campaigns.',
  },

  LogoUploader: {
    FileSizeLimitExceededError: `Sorry! We can’t upload this image. It should have up to ${bytes(
      logoUploader.fileSizeLimit,
    )}`,
    WrongExtensionError: 'File should has one of these extension: {{extensions}}',
    FileRequirements: `Allowed ${logoUploader.accept
      .split(', ')
      .map((item) => `*${item}`)
      .join(', ')} max size of ${bytes(logoUploader.fileSizeLimit)}`,
    RemoveButton: 'Delete photo',
    DeleteConfirmation: {
      Title: 'Delete photo',
      Description: 'Are you sure you want to delete the photo?',
      ConfirmButton: 'Delete',
    },
    RemoveButtonOrganization: 'Delete logo',
    DeleteConfirmationOrganization: {
      Title: 'Delete organization logo',
      Description: 'Are you sure you want to delete the organization logo?',
      ConfirmButton: 'Delete',
    },
  },
  ReportsOverview: {
    CountPerDayLabel: '<strong>{{countPerDay}}</strong> per day',
  },
  Reports: {
    ImpressionsTitle: 'Impressions',
    ClicksTitle: 'Clicks',
    CostPerClicksTitle: 'Cost per clicks',
    ClickThroughRateTitle: 'Click through rate',
  },
  CreateCampaignModal: {
    Title: 'Create campaign',
    ComingSoon: 'COMING SOON',
    Labels: {
      Building: 'Building',
      Listing: 'Listing',
      OpenHouse: 'Open House',
      BrandingCampaign: 'Branding Campaign',
    },
    Descriptions: {
      Building: 'Drive awareness and leads for multifamily buildings/listings',
      Listing: 'Drive leads for individual listings',
      OpenHouse: 'Designed to drive signs ups for open houses',
      BrandingCampaign: 'Raise general brand awareness and drive leads for your company',
    },
  },

  TextPicker: {
    PrevButton: 'Previous',
    NextButton: 'Next',
    ShuffleButton: 'Shuffle',
    RegenerateDescriptions: 'Regenerate descriptions',
    Progress: 'Choice #{{current}} of {{total}}',
    AddAdditionalTitle: 'Add additional title',
    AddAdditionalDescription: 'Add additional description',
  },

  KeywordsPicker: {
    Labels: {
      AdGroupName: 'AdGroup Name',
      Keywords: 'Keywords',
    },
    Placeholders: {
      AdGroupName: 'AdGroup Name',
      Keywords: 'Keywords',
      CustomGroup: 'Please enter custom item',
    },
    RemoveOldKeywords: 'Remove old keywords',
    AddButton: 'Add AdGroup Name',
  },

  AdTextPicker: {
    Title: 'Editing Ad preview',
    Labels: {
      Description: 'Description',
      DescriptionOrdinal: '{{current}} description',
      DescriptionOrdinalMandatory: '{{current}} description *',
      Title: 'Title',
      TitleOrdinal: '{{current}} title',
      TitleOrdinalMandatory: '{{current}} title *',
    },
    Placeholder: {
      Description: 'Description',
      DescriptionOrdinal: '{{current}} description',
      Title: 'Title',
      TitleOrdinal: '{{current}} title',
    },
    SaveButton: 'Save changes',
    Items: {
      First: 'First',
      Second: 'Second',
      Third: 'Third',
      Fourth: 'Fourth',
      Fifth: 'Fifth',
      Sixth: 'Sixth',
      Seventh: 'Seventh',
      Eighth: 'Eighth',
      Ninth: 'Ninth',
      Tenth: 'Tenth',
      Eleventh: 'Eleventh',
      Twelfth: 'Twelfth',
      Thirteenth: 'Thirteenth',
      Fourteenth: 'Fourteenth',
      Fifteenth: 'Fifteenth',
    },
  },

  AdLogoPicker: {
    Title: 'Logo settings',
    Labels: {
      Logo: 'Logo pictures',
    },
    SaveButton: 'Save changes',
  },

  AdKeywordsPicker: {
    Title: 'AdGroup settings',
    SaveButton: 'Save changes',
  },

  ImagesPicker: {
    Title: 'Image settings',
    Opener: 'Image Settings',
    Labels: {
      SelectedImages: 'Selected images (max {{amount}} images)',
      AllImages: 'All images',
      ApprovedImages: 'Approved creative assets',
    },
    UndoSelectButton: 'Undo select',
    SelectButton: 'Select',
    SaveButton: 'Save changes',
  },

  ImageEdit: {
    Title: 'Edit image',
    Tooltip:
      'The "Edit image" popup allows the user to edit the image so that the Facebook and Google platforms can correctly display images in ads. Note: The aspect ratio of 1.91:1 is used primarily for advertising on the Google platforms.',
    TooltipLogo:
      'The "Edit image" popup allows the user to edit the image so that Google platforms can correctly display logo image in ads.',
    Scale: 'Scale',
    AspectRatio: 'Aspect ratio',
    SaveButton: 'Save changes',
    InformationForSquare: '*minimal Display image size 300x300 px',
    InformationForRectangle: '*minimal Display image size 600x314 px',
    InformationForSquareLogo: '*minimal logo size 128x128 px',
    InformationForRectangleLogo: '*minimal logo size 512x128 px',
  },

  OrganizationDetailsPage: {
    Photo: {
      Title: 'Organization logo',
      TitleTooltip: 'The "Organization logo" block allows the user to change the logo of the organization',
    },
    Info: {
      Title: 'Organization info',
      TitleTooltip: 'The "Organization info" block allows the user to change the information about the organization',
      Labels: {
        Name: 'Organization Name',
        Type: 'Organization Type',
        Website: 'Organization Website',
        TypesOfProperties: 'Types of properties',
        Description: 'Description',
        State: 'State',
        City: 'City',
        Phone: 'Leasing office phone number',
      },
      EditButton: 'Edit info',
      EditModal: {
        Title: 'Edit organization info',
        Labels: {
          Name: 'Organization Name',
          Website: 'Organization Website',
          TypesOfProperties: 'Types of properties',
          Description: 'Description',
          State: 'State',
          City: 'City',
          Phone: 'Leasing office phone number',
        },
        Placeholders: {
          Name: 'Organization Name',
          Website: 'Organization Website',
          TypesOfProperties: 'Types of properties',
          Description: 'Description',
          State: 'State',
          City: 'City',
          Phone: 'Leasing office phone number',
        },
        SubmitButton: 'Save changes',
      },
    },
    Buildings: {
      Title: 'Building info',
      TitleTooltip:
        'The "Building info" block allows the user to add a portfolio of existing buildings of the organization. The Minerva system will use this information in creating a branding campaign.',
      BuildingInfo: {
        Name: 'Building name',
        Link: 'Building link',
        State: 'State',
        City: 'City',
        Neighborhood: 'Neighborhood',
        MinPrice: 'Minimum Price',
        MaxPrice: 'Maximum Price',
        BedroomCount: 'Bedroom count',
        EditBuilding: 'Edit building',
      },
      NoBuildings: 'You have no buildings',
      PleaseAddBuildingInfo: 'Please, add building info',
    },
    Photos: {
      Title: 'Organization photos',
      TitleTooltip:
        'The "Organization photos" block allows the user to upload photos to the organization. The user can use these photos when creating a branding campaign',
      SubmitButton: 'Save Photos',
    },
    Users: {
      Toolbar: {
        Title: 'Users',
        TitleTooltip: 'The "Users" block allows the owner of the organization to invite users to the organization',
        AddUserButton: 'Add user',
      },
      Table: {
        Columns: {
          Name: 'Name',
          LastName: 'Last name',
          Email: 'Email',
          InviteStatus: 'Invite status',
          ResendInvite: 'Resend invite',
          Options: 'Options',
        },
        ResendInviteButton: 'Resend invite',
        RemoveUserConfirmation: {
          Title: 'Delete user',
          Description: 'Are you sure you want to delete the user?',
          ConfirmText: 'Delete',
        },
      },
      Modal: {
        Title: 'Add user',
        Labels: {
          Name: 'Name',
          LastName: 'Last name',
          Email: 'Email',
        },
        Placeholders: {
          Name: 'Name',
          LastName: 'Last name',
          Email: 'Email',
        },
        SubmitButton: 'Add user and send invite',
        Alerts: {
          Created: 'User invited',
        },
      },
    },
    Alerts: {
      LogoUpdated: 'Organization logo was updated',
      InfoUpdated: 'Organization info was updated',
      PhotosUpdated: 'Organization photos were updated',
      AffectedCampaignsWarning:
        'Due to changes in the organization, some of your campaigns have been updated. Please check the highlighted campaigns',
    },
  },

  CampaignsPage: {
    Title: 'Campaigns',
    CreateCampaignButton: 'Create campaign',
    Table: {
      Columns: {
        Name: 'Campaign name',
        PropertyName: 'Property name',
        RunDates: 'Run Dates',
        CampaignType: 'Campaign Type',
        Agent: 'Campaign owner',
        Reports: 'Reports',
        Status: 'Status',
        Details: 'Details',
      },
      ReportButton: 'View report',
      UpdatedCampaignTooltip: 'Updated campaign',
    },
    Toolbar: {
      CreateBuildingCampaignButton: 'Create building campaign',
      CreateListingCampaignButton: 'Create listing campaign',
      CreateOpenSpaceCampaignButton: 'Create open space campaign',
    },
  },

  BuildingsPage: {
    Title: 'Buildings',
    AddNewButton: 'Add building',
    Table: {
      Columns: {
        Name: 'Building name',
        Address: 'Address',
        Neighborhood: 'Neighborhood',
        Url: 'URL',
        PropertyType: 'Property type',
        Details: 'Details',
      },
    },
  },

  ListingsPage: {
    Title: 'Listings',
    AddNewButton: 'Add listing',
    Table: {
      Columns: {
        Name: 'Listing name',
        Address: 'Address',
        Neighborhood: 'Neighborhood',
        Url: 'URL',
        PropertyType: 'Property type',
        Details: 'Details',
      },
    },
  },

  CampaignReportsPage: {
    Title: 'Report',
    Info: {
      Name: 'Campaign name',
      RunDates: 'Run Dates',
    },
    Tabs: {
      Overall: 'Overall',
      FacebookAndInstagram: '<0></0> Facebook / <1></1> Instagram',
      GoogleAdwords: 'Google Search',
      GoogleAds: 'Display',
    },
    ReportsOverview: {
      Spend: 'Spend',
      CostPerLead: 'Cost per Lead',
      CostPerTour: 'Cost per Tour',
      Leads: 'Leads',
      Impressions: 'Impressions',
      Clicks: 'Clicks',
      ClicksThroughRates: 'Clicks through rates',
      CostPerUniqueClick: 'Cost per unique click',
      Conversions: 'Conversions',
      CostPerLeadCostPerСonversion: 'Cost per Lead/Cost per Conversion',
      CostPerConversion: 'Cost per Conversion',
      LeadsConversions: 'Leads/Conversions',
      CostPerClick: 'Cost per click',
    },
    Charts: {
      CostPerLead: 'Cost Per Lead',
      CostPerApplication: 'Customer Conversion Funnel',
      Analytics: 'Time Series',
      AgeBreakdown: 'Age Breakdown',
      AgeGenderBreakdown: 'Age/Gender Breakdown',
      RegionBreakdown: 'Region Breakdown',
      GenderBreakdownTotalImpressions: 'Gender Breakdown: Total Impressions',
      GenderBreakdownTotalClicks: 'Gender Breakdown: Total Clicks',
    },
    Alerts: {
      FbAndInstagramRequestsLimitExceeded:
        'The system can send a limited number of requests per hour to update reports according to Facebook rules. You have used your request limit per hour, and due to repeated requests, Facebook may block your account. Please try updating reports in one hour.',
    },
  },
  ProfilePage: {
    Photo: {
      Title: 'Profile photo',
      Tooltip: 'The "Profile photo" block allows the user to change his photo of the profile',
    },
    ContactInfo: {
      Title: 'Contact info',
      Tooltip: 'The "Contact info" block allows the user to change the user\'s contact information',
      EditButton: 'Edit info',
      Labels: {
        Name: 'Name',
        LastName: 'Last Name',
        ProfileType: 'Profile type',
        Phone: 'Phone',
        Website: 'Website',
        Email: 'E-mail',
        Bio: 'Bio',
        State: 'State',
        City: 'City',
      },
      EditModal: {
        Title: 'Edit contact info',
        Labels: {
          Name: 'Name',
          LastName: 'Last Name',
          Phone: 'Phone',
          Website: 'Website',
          Bio: 'Bio',
          State: 'State',
          City: 'City',
        },
        Placeholders: {
          Name: 'Name',
          LastName: 'Last Name',
          Phone: 'Phone',
          Website: 'Website',
          Bio: 'Bio',
          State: 'State',
          City: 'City',
        },
        SubmitButton: 'Save changes',
      },
    },
    Password: {
      Title: 'Password',
      Tooltip: 'The "Password" block allows the user to change the user\'s password',
      ChangePasswordButton: 'Change password',
      Modal: {
        Title: 'Change password',
        Labels: {
          CurrentPassword: 'Current password',
          NewPassword: 'New password',
          ConfirmNewPassword: 'Confirm new password',
        },
        Placeholders: {
          CurrentPassword: 'Current password',
          NewPassword: 'New password',
          ConfirmNewPassword: 'Confirm new password',
        },
        ForgotPasswordLink: 'Forgot password?',
        SubmitButton: 'Save changes',
      },
    },
    Platforms: {
      Title: 'Platforms',
      Tooltip:
        'The "Platforms" block allows the user to connect advertising systems to the account. This is necessary to be able to place advertising campaigns',
      ConnectPlatformButton: 'Connect platform',
      Connect: 'Connect',
      Disconnect: 'Disconnect',
      Connected: 'Connected',
      NotConnected: 'Connect platform',
      ConnectGoogle: 'Sign In with Google',
      ConnectFacebook: 'Continue with Facebook',
      ConnectedPlatform: 'You are already connected with {{platform}}',
      Modal: {
        Title: 'Connect platform',
        Facebook: {
          Title: 'Facebook',
          Description:
            'By connecting your account with your Facebook and Instagram account, you acknowledge and agree that information you choose to share will be uploaded to Facebook and Instagram and may be viewed by Facebook and Instagram and other Facebook and Instagram users. Also, your Facebook and Instagram account information may be used by Minerva. Minerva will not publicly display your Facebook and Instagram account information. If you no longer want to share this information, please disconnect your Facebook and Instagram account.',
          FacebookAdAccounts: {
            Title: 'Choose Facebook Ad account',
            Columns: {
              BusinessAccountLogo: 'Business account logo',
              BusinessAccount: 'Business account',
              AdAccount: 'Ad account',
              Options: 'Options',
              Connect: 'Connect',
            },
            Errors: {
              ErrorPermissions:
                'Minerva cannot connect your Facebook account because you have not provided enough permissions. Remaining needed permissions: {{errors}}',
              ErrorAdAccounts:
                'There are no Ad accounts inside your Facebook account. Please, create an Ad account and try again.',
            },
          },
        },
        Google: {
          Title: 'Google',
          Description:
            'By connecting your account with your Google account, you acknowledge and agree that information you choose to share will be uploaded to Google and may be viewed by Google and other Google users. Also, your Google account information may be used by Minerva. Minerva will not publicly display your Google account information. If you no longer want to share this information, please disconnect your Google account.',
          GoogleAdAccounts: {
            Title: 'Choose Google Manager account',
            Columns: {
              ManagerAccount: 'Manager account',
              ManagerAccountId: 'Manager account ID',
              Options: 'Options',
              Connect: 'Connect',
            },
            Errors: {
              ErrorManageAccount:
                'There are no manager accounts inside your Google account. Please, create a manager account and try again.',
            },
          },
        },
        Tooltip: {
          Title:
            'The "Connect platform" block allows the user to fill in identifying credentials for Facebook connection <0></0> <1>F.A.Q.</1> for detailed instructions',
        },
      },
    },
    FeaturedPhotography: {
      Title: 'Profile photos',
      Tooltip:
        'The "Profile photos" block allows the user to upload photos to the user\'s profile. The user can use these photos when creating a branding campaign',
      Labels: {
        ProfilePhotos: 'Profile photos',
      },
      SubmitButton: 'Save photos',
    },
    Alerts: {
      ConnectPlatformFacebookSuccess: 'Success connection. You will need to reconnect account after 60 days',
      ConnectPlatformFacebookFail: 'Fail connection',
      AvatarUpdated: 'Profile photo was updated',
      ContactInfoUpdated: 'Contact info was updated',
      PasswordUpdated: 'Password was updated',
      FeaturedPhotosUpdated: 'Profile photos were updated',
      AffectedCampaignsWarning:
        'Due to changes in the profile, some of your campaigns have been updated. Please check the highlighted campaigns',
    },
  },

  DashboardPage: {
    Welcome: {
      Welcome: 'Welcome',
      Title: 'Create a campaign, promote it and be successful',
    },
    Campaigns: {
      Title: 'Campaigns',
    },
  },
};

export default en;
