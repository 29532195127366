import { BaseHttpClient } from '../base-http-client';
import { createAuthApi } from './auth-api';
import { createUsersApi } from './users-api';
import { createCampaignsApi } from './campaigns-api';
import { createReportsApi } from './reports-api';
import { createBuildingsApi } from './buildings-api';
import { createListingsApi } from './listings-api';
import { createOpenHousesApi } from './open-houses-api';
import { createGeoLocationsApi } from './geo-locations-api';
import { createApartmentParametersApi } from './appartment-parameters-api';
import { createOrganizationsApi } from './organizations-api';
import { createKeywordsApi } from './keywords-api';
import { createAdSocialNetworksApi } from './ad-social-networks-api';
import { createABTestingApi } from './ab-testing-api';
import { createVersionApi } from './version-api';
import { ApiConfig } from '../../types';
import { createAdGroupsApi } from './google-ad-groups-api';

/**
 * Manages all requests to the API.
 */
export const createClient = (config: ApiConfig) => {
  const http = new BaseHttpClient(config);
  return {
    ...http,
    ...createAuthApi(http),
    ...createUsersApi(http),
    ...createCampaignsApi(http),
    ...createReportsApi(http),
    ...createBuildingsApi(http),
    ...createListingsApi(http),
    ...createOpenHousesApi(http),
    ...createGeoLocationsApi(http),
    ...createApartmentParametersApi(http),
    ...createOrganizationsApi(http),
    ...createKeywordsApi(http),
    ...createAdSocialNetworksApi(http),
    ...createABTestingApi(http),
    ...createAdGroupsApi(http),
    ...createVersionApi(http),
  };
};

export type ApiClient = ReturnType<typeof createClient>;
