import { useForm } from 'hooks';
import get from 'lodash.get';
import { useCallback } from 'react';
import { useToggle } from 'react-use';
import { ImageSaved } from 'types';
import { ImagesPickerModal } from '../images-picker-modal';
import { ImagesPickerProps } from '../types';

interface ModalPicker {
  toggleOpen: () => void;
  modal?: React.ReactNode;
}

export const useModalPicker = (
  name: string,
  onChange: (e: any) => void,
  onBlur: (e: any) => void,
  imageOptions?: ImageSaved[],
  max?: ImagesPickerProps['max'],
  approvedImageOptions?: ImageSaved[],
): ModalPicker => {
  const [open, toggleOpen] = useToggle(false);

  const { values } = useForm();

  const value = get(values, name);

  const handleChange = useCallback(
    (value: ImagesPickerProps['value']) => {
      const event = { target: { name, value } };

      onChange(event);
      onBlur(event);
    },
    [name, onBlur, onChange],
  );

  const modal = imageOptions?.length ? (
    <ImagesPickerModal
      approvedImageOptions={approvedImageOptions}
      visible={open}
      onCancel={toggleOpen}
      value={value}
      options={imageOptions}
      onSubmit={handleChange}
      max={max}
    />
  ) : null;

  return { toggleOpen, modal };
};
