import { Instance, SnapshotOut, types, flow, toGenerator, applySnapshot } from 'mobx-state-tree';
import { History } from 'history';
import { withFlashAlerts } from 'stores/extensions';
import User from 'stores/models/user';
import Jwt from 'stores/models/jwt';
import BaseModel from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import locations from 'navigation/locations';
import * as qs from 'utils/query-string';
import { setSentryUser } from 'services/error-report/sentry';
import FacebookAdAccounts from 'stores/models/facebook-ad-accounts';
import GoogleManagerAccounts from 'stores/models/google-manager-accounts';
import { handleActivationTokenError } from 'services/api/errors';
import config from 'config';
import {
  AuthTokenPairs,
  PostSignUpApi,
  PostSignInApi,
  PostRefreshTokenApi,
  PostForgotPasswordApi,
  PostRestorePasswordApi,
  PostLogoutApi,
} from 'types';
import { deserializeAuthTokenPairs, deserializeOrganizationOptions, deserializeUserOptions } from 'utils/deserializers';
import { createDestParamForLocation } from 'utils/locations';
import { fromListResponse } from 'services/api';
import { isEmpty } from 'utils/is';
import { serializeSelectedUser } from 'utils/serializers';

export interface SignUpFormValues {
  name: string;
  lastName: string;
  email: string;
  state: string;
  city: string;
  password: string;
  confirmPassword: string;
  contactPhone: string;
  promoCode: string;
}

export interface SignInFormValues {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface ForgotPasswordFormValues {
  email: string;
}

export interface RestorePasswordFormValues {
  uid: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface RefreshTokenValues {
  refreshToken: string;
}

export const staticMethods = {
  toSignUpPostRequest: (values: SignUpFormValues): PostSignUpApi => ({
    first_name: values.name,
    last_name: values.lastName,
    email: values.email,
    state: Number(values.state),
    city: Number(values.city),
    password1: values.password,
    password2: values.confirmPassword,
    contact_phone: values.contactPhone,
    promo_code: values.promoCode,
  }),

  toSignInPostRequest: (values: SignInFormValues): PostSignInApi => ({
    username: values.username,
    password: values.password,
    remember_me: values.rememberMe,
  }),

  toRefreshTokenPostRequest: ({ refreshToken }: RefreshTokenValues): PostRefreshTokenApi => ({
    refresh: refreshToken,
  }),

  toLogoutPostRequest: ({ refreshToken }: RefreshTokenValues): PostLogoutApi => ({
    refresh: refreshToken,
  }),

  toForgotPasswordPostRequest: ({ email }: ForgotPasswordFormValues): PostForgotPasswordApi => ({
    email,
  }),

  toRestorePasswordPostRequest: ({
    uid,
    token,
    password,
    confirmPassword,
  }: RestorePasswordFormValues): PostRestorePasswordApi => ({
    uid,
    token,
    password1: password,
    password2: confirmPassword,
  }),
};

type RedirectLink = string | undefined;
type GetRedirectLink = () => RedirectLink;

export const SessionStore = BaseModel.named('SessionStore')
  .props({
    tokens: types.maybe(Jwt),
    user: types.maybe(User),
    facebookAdAccounts: types.optional(FacebookAdAccounts, {}),
    googleManagerAccounts: types.optional(GoogleManagerAccounts, {}),
    selectedUser: types.maybe(User),
    isSigningOut: types.optional(types.boolean, false),
    isSelectedUser: types.optional(types.boolean, false),
    isError: types.optional(types.boolean, false),
  })
  .extend(withFlashAlerts)
  .views((self) => ({
    get isSignedIn() {
      return Boolean(self.tokens && self.user);
    },

    get isSigningIn() {
      return this.isSignedIn && !self.user;
    },

    get location() {
      const history = self.services.get<History>('history');

      return history.location;
    },

    get facebookAdAccountsList() {
      return [...self.facebookAdAccounts.entries];
    },

    get isOrganizationOwner() {
      if (self.isSelectedUser) {
        return self.selectedUser?.isOrganizationOwner;
      }
      return self.user?.isOrganizationOwner;
    },

    get googleManagerAccountsList() {
      return [...self.googleManagerAccounts.entries];
    },

    getLoggedInUserLocation(destUrl?: string): RedirectLink {
      if (!self.user?.hasOrganization) {
        return locations.organization.setup.toUrl();
      }

      const { location } = this;
      const { pathname: pagePathname } = location;
      const isLoginPage = pagePathname === locations.signInWithPassword.toUrl();

      if (isLoginPage) {
        const { dest } = qs.parse(location.search);

        return typeof dest === 'string' && dest
          ? new URL(dest, window.location.origin)?.pathname
          : config.options.pages.defaultPage;
      }

      return destUrl;
    },

    loadUser: apiFlow(function* loadUser() {
      const response = yield* toGenerator(self.api.getUser());

      const user = User.fromResponseData(response.data);

      if (self.user) {
        applySnapshot(self.user, user);
        return;
      }

      self.user = User.create(user);
    }),

    refreshUser: flow(function* refreshUser() {
      const response = yield* toGenerator(self.api.getUser());

      applySnapshot(self, {
        ...self,
        user: User.create(User.fromResponseData(response.data)),
      });

      if (self.isSelectedUser && self.user?.profileId) {
        const response = yield self.api.getSelectedUser({
          id: self.user?.profileId,
        });

        applySnapshot(self, {
          ...self,
          selectedUser: User.create(User.fromResponseData(response.data)),
        });
      }
    }),
  }))
  .actions((self) => ({
    moveLoggedInUserToInitialPage() {
      const history = self.services.get<History>('history');
      const redirectLocation = self.getLoggedInUserLocation();

      if (redirectLocation) {
        history.replace(redirectLocation);
      }
    },
  }))
  .actions((self) => ({
    initLoggedInUser: apiFlow(function* initLoggedInUser({
      getRedirectLink,
    }: {
      getRedirectLink?: GetRedirectLink;
    } = {}) {
      yield self.loadUser();
      if (!self.user) {
        return;
      }

      setSentryUser({
        id: self.user.id,
        email: self.user.email,
      });

      setTimeout(() => {
        const history = self.services.get<History>('history');

        const redirectLink = getRedirectLink && getRedirectLink();

        if (redirectLink) {
          history.replace(redirectLink);
        }
      });
    }),

    setTokens(tokens: AuthTokenPairs) {
      self.tokens = Jwt.create(tokens);
    },

    setError(error: boolean) {
      self.isError = error;
    },

    resetSession: apiFlow(function* resetSession() {
      const refreshToken = self.tokens?.refreshToken;

      if (refreshToken) {
        yield self.api.logout(staticMethods.toLogoutPostRequest({ refreshToken }));
      }

      self.tokens = undefined;
      self.user = undefined;
      self.selectedUser = undefined;
    }),

    getOrganizations: apiFlow(function* getOrganizations() {
      const response = yield* toGenerator(self.api.getOrganizations());

      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOrganizationOptions(data);
    }),

    getUserByOrganization: apiFlow(function* getUserByOrganization(id) {
      const response = yield* toGenerator(self.api.getUsersByOrganization(id));

      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeUserOptions(data);
    }),

    updateSelectedUser: apiFlow(
      function* updateSelectedUser(serializedData: { organization: string; user: string }) {
        const id = self.user?.id;

        if (isEmpty(id)) {
          return;
        }

        const responseUser = yield self.api.patchSelectedUser({
          // @ts-ignore
          id,
          data: serializeSelectedUser(serializedData),
        });

        const { profileId } = User.fromResponseData(responseUser.data);

        if (isEmpty(profileId)) {
          return;
        }

        const responseSelectedUser = yield self.api.getSelectedUser({
          // @ts-ignore
          id: profileId,
        });

        const selectedUser = User.fromResponseData(responseSelectedUser.data);

        self.selectedUser = User.create(selectedUser);

        self.isSelectedUser = true;

        window.location.reload();
      },
      {
        isUpdate: true,
      },
    ),

    backToUser: apiFlow(
      function* backToUser() {
        const id = self.user?.id;

        if (isEmpty(id)) {
          return;
        }

        yield self.api.patchSelectedUser({
          // @ts-ignore
          id,
          data: {
            supported_organization: null,
            supported_user: null,
          },
        });

        self.selectedUser = undefined;

        self.isSelectedUser = false;

        self.refreshUser();

        window.location.reload();
      },
      {
        isUpdate: true,
      },
    ),
  }))
  .actions((self) => ({
    signInByTokens: apiFlow(function* signInByTokens({
      tokens,
      getRedirectLink,
    }: {
      tokens: AuthTokenPairs;
      getRedirectLink?: GetRedirectLink;
    }) {
      yield self.resetSession();

      self.setTokens(tokens);

      yield self.initLoggedInUser({ getRedirectLink });
    }),

    refreshToken: flow(function* refreshToken(data: { refreshToken: string }) {
      const response = yield* toGenerator(self.api.refreshToken(staticMethods.toRefreshTokenPostRequest(data)));

      self.setTokens(deserializeAuthTokenPairs(response.data));
    }),

    signOut: apiFlow(function* signOut(saveDest = false) {
      self.isSigningOut = true;
      yield self.resetSession();

      const { location } = self;
      const dest = saveDest ? createDestParamForLocation(location) : null;
      const history = self.services.get<History>('history');

      history.replace(locations.signInWithPassword.toUrl(dest ? { dest } : {}));
      self.isSigningOut = false;
      self.isSelectedUser = false;
    }),
  }))
  .actions((self) => ({
    bootstrap: flow(function* bootstrap() {
      try {
        if (self.isSignedIn) {
          yield self.initLoggedInUser({
            getRedirectLink: () => self.getLoggedInUserLocation(),
          });
        }
      } catch (e) {
        console.log(e);
      }
    }),

    signUp: apiFlow(
      function* signUp(values: SignUpFormValues) {
        yield self.api.signUp(staticMethods.toSignUpPostRequest(values));
      },
      { formName: 'sign-up' },
    ),

    activateAccount: apiFlow<{
      isTokenInvalid: boolean;
    } | null>(
      function* activateAccount({ uid, token }: { uid: string; token: string }) {
        try {
          const response = yield* toGenerator(self.api.activateAccount({ uid, token }));

          setTimeout(() => {
            self.signInByTokens({
              tokens: deserializeAuthTokenPairs(response.data),
              getRedirectLink: () => self.getLoggedInUserLocation(config.options.pages.defaultPage),
            });
          }, config.options.auth.initialRedirectAfterAccountActivationDelay);

          return null;
        } catch (error) {
          return handleActivationTokenError(error);
        }
      },
      { formName: 'account-activation' },
    ),

    signIn: apiFlow(
      function* signIn(values: SignInFormValues) {
        const response = yield* toGenerator(self.api.signIn(staticMethods.toSignInPostRequest(values)));

        yield self.signInByTokens({
          tokens: deserializeAuthTokenPairs(response.data),
        });
      },
      { formName: 'sign-in-with-password' },
    ),

    forgotPassword: apiFlow(
      function* forgotPassword(values: ForgotPasswordFormValues) {
        yield self.api.forgotPassword(staticMethods.toForgotPasswordPostRequest(values));
      },
      {
        formName: 'forgot-password',
      },
    ),

    restorePassword: apiFlow(
      function* restorePassword(values: RestorePasswordFormValues) {
        const response = yield* toGenerator(
          self.api.restorePassword(staticMethods.toRestorePasswordPostRequest(values)),
        );

        yield self.signInByTokens({
          tokens: deserializeAuthTokenPairs(response.data),
          getRedirectLink: () => self.getLoggedInUserLocation(config.options.pages.defaultPage),
        });
      },
      {
        formName: 'restore-password',
        successAlert: 'Auth.RestorePassword.SuccessAlert',
      },
    ),

    fetchFacebookAdAccounts: self.facebookAdAccounts.fetch,

    fetchGoogleManagerAccounts: self.googleManagerAccounts.fetch,
  }));

export interface SessionStoreInstance extends Instance<typeof SessionStore> {}
export interface SessionStoreSnapshot extends SnapshotOut<typeof SessionStore> {}
