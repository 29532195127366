import React, { Fragment, lazy, ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import config from 'config';
import { Router } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import { withSuspense } from 'hocs/with-suspense';
import { useServices } from 'hooks/use-services';
import { AppContainer } from 'components/layout/app-container';
import { PublicRoute, AuthenticatedRoute, UnauthenticatedRoute, RouteProps } from 'components/navigation';
import SignUpPage from 'pages/auth/sign-up';
import InvitedSignUpPage from 'pages/auth/invited-sign-up';
import AccountActivationPage from 'pages/auth/account-activation';
import SignInWithPasswordPage from 'pages/auth/sign-in-with-password';
import ForgotPasswordPage from 'pages/auth/forgot-password';
import RestorePasswordPage from 'pages/auth/restore-password';
import { useStores } from 'hooks';
import locations from './locations';

const AuthLayout = withSuspense(lazy(() => import('components/layout/auth/layout')));

const SetupLayout = withSuspense(lazy(() => import('components/layout/setup')));

const CoreLayout = withSuspense(lazy(() => import('components/layout/core/layout')));

const TermsOfServicePage = lazy(() => import('pages/auth/terms-of-service'));
const PrivacyPolicyPage = lazy(() => import('pages/auth/privacy-policy'));

const OrganizationSetupPage = lazy(() => import('pages/organization-setup'));
const OrganizationDetailsPage = lazy(() => import('pages/organization-details'));

const DashboardPage = lazy(() => import('pages/dashboard'));
const ProfilePage = lazy(() => import('pages/profile'));

const MarketingDescriptionPage = lazy(() => import('pages/marketing-description'));
const CreativeIntelligencePage = lazy(() => import('pages/creative-intelligence'));
const CreativeManagementPage = lazy(() => import('pages/creative-management'));

const CampaignsPage = lazy(() => import('pages/campaigns'));
const BuildingCampaignPage = lazy(() => import('pages/building-campaign'));
const ListingCampaignPage = lazy(() => import('pages/listing-campaign'));
const BrandingCampaignPage = lazy(() => import('pages/branding-campaign'));
const CampaignReportsPage = lazy(() => import('pages/campaign-reports'));

const BuildingsPage = lazy(() => import('pages/buildings'));
const BuildingPage = lazy(() => import('pages/building'));

const ListingsPage = lazy(() => import('pages/listings'));
const ListingPage = lazy(() => import('pages/listing'));

const ReportsPage = lazy(() => import('pages/reports'));

const NotFoundPage = lazy(() => import('pages/not-found'));

const ABTestingPage = lazy(() => import('pages/ab-testing-campaign'));

const FrequentlyAskedQuestion = lazy(() => import('pages/frequently-asked-question'));

const buildingPath = [locations.buildings.newBuilding.path, locations.buildings.building.path];

const listingPath = [locations.listings.newListing.path, locations.listings.listing.path];

const buildingCampaignPath = [locations.campaigns.newBuildingCampaign.path, locations.campaigns.buildingCampaign.path];

const listingCampaignPath = [locations.campaigns.newListingCampaign.path, locations.campaigns.listingCampaign.path];

const brandingCampaignPath = [locations.campaigns.newBrandingCampaign.path, locations.campaigns.brandingCampaign.path];

const authenticatedIndexPaths = [locations.signUp.path, locations.signInWithPassword.path, ''];

interface RouterSettings extends Pick<RouteProps, 'layout'> {
  path?: RouteProps['path'];
  disabled?: boolean;
  content: ReactNode;
  route: typeof AuthenticatedRoute | typeof PublicRoute;
}

export const AppNavigator = observer(() => {
  const container = useServices();

  const { session } = useStores();
  const { user } = session;

  const history = container.get<History>('history');

  const campaignRouteSettings = useMemo(
    () => [
      {
        path: locations.campaigns.list.path,
        content: <CampaignsPage />,
      },
      {
        path: buildingCampaignPath,
        content: <BuildingCampaignPage />,
      },
      {
        path: listingCampaignPath,
        content: <ListingCampaignPage />,
      },
      {
        path: brandingCampaignPath,
        content: <BrandingCampaignPage />,
      },
      {
        path: locations.campaigns.campaignReports.path,
        content: <CampaignReportsPage />,
      },
      {
        path: locations.campaigns.abTesting.path,
        content: <ABTestingPage />,
      },
    ],
    [],
  );

  const buildingRouteSettings = useMemo(
    () => [
      {
        path: buildingPath,
        content: <BuildingPage />,
      },
      {
        path: locations.buildings.list.path,
        content: <BuildingsPage />,
      },
    ],
    [],
  );

  const listingRouteSettings = useMemo(
    () => [
      {
        path: listingPath,
        content: <ListingPage />,
      },
      {
        path: locations.listings.list.path,
        content: <ListingsPage />,
      },
    ],
    [],
  );

  const authenticatedRouteSettings = useMemo<RouterSettings[]>(
    () =>
      [
        ...campaignRouteSettings,
        ...buildingRouteSettings,
        ...listingRouteSettings,

        {
          disabled: !user?.isOrganizationDetailsAvailable,
          path: locations.organization.details.path,
          content: <OrganizationDetailsPage />,
        },
        {
          path: locations.dashboard.path,
          content: <DashboardPage />,
        },
        {
          path: locations.profile.path,
          content: <ProfilePage />,
        },
        {
          path: locations.reports.list.path,
          content: <ReportsPage />,
        },
        {
          path: locations.marketingDescription.path,
          content: <MarketingDescriptionPage />,
        },
        {
          path: locations.creativeIntelligence.path,
          content: <CreativeIntelligencePage />,
        },
        {
          path: locations.creativeManagement.path,
          content: <CreativeManagementPage />,
        },
      ].map((routeSettings) => ({
        layout: CoreLayout,
        ...routeSettings,
        route: AuthenticatedRoute,
      })),
    [buildingRouteSettings, campaignRouteSettings, listingRouteSettings, user?.isOrganizationDetailsAvailable],
  );

  const authRouteSettings = useMemo(
    () =>
      [
        {
          path: locations.signUp.path,
          content: <SignUpPage />,
        },
        {
          path: locations.invitedSignUp.path,
          content: <InvitedSignUpPage />,
        },
        {
          path: locations.accountActivation.path,
          content: <AccountActivationPage />,
        },
        {
          path: locations.signInWithPassword.path,
          content: <SignInWithPasswordPage />,
        },
        {
          path: locations.forgotPassword.path,
          content: <ForgotPasswordPage />,
        },
        {
          path: locations.restorePassword.path,
          content: <RestorePasswordPage />,
        },
      ].map((route) => ({ layout: AuthLayout, ...route })),
    [],
  );

  const setupRouteSettings = useMemo(
    () =>
      [
        {
          path: locations.termsOfService.path,
          content: <TermsOfServicePage />,
        },
        {
          disabled: user?.hasOrganization,
          path: locations.organization.setup.path,
          content: <OrganizationSetupPage />,
        },
        {
          path: locations.privacyPolicy.path,
          content: <PrivacyPolicyPage />,
        },
        {
          path: locations.frequentlyAskedQuestion.path,
          content: <FrequentlyAskedQuestion />,
        },
      ].map((route) => ({ layout: SetupLayout, ...route })),
    [user?.hasOrganization],
  );

  const publicRouteSettings = useMemo<RouterSettings[]>(
    () =>
      [...authRouteSettings, ...setupRouteSettings].map((routeSettings) => ({
        ...routeSettings,
        route: PublicRoute,
      })),
    [authRouteSettings, setupRouteSettings],
  );

  const routes = useMemo(
    () =>
      [...authenticatedRouteSettings, ...publicRouteSettings]
        .filter(({ disabled }) => !disabled)
        .map(({ path, content, layout, route: Route }, index) => (
          <Route key={index} layout={layout} exact path={path}>
            {content}
          </Route>
        )),
    [authenticatedRouteSettings, publicRouteSettings],
  );

  return (
    <Router history={history}>
      <AppContainer>
        <Switch>
          {routes}
          <>
            <UnauthenticatedRoute>
              <Redirect to={locations.signInWithPassword.toUrl()} />
            </UnauthenticatedRoute>
            <Switch>
              <AuthenticatedRoute exact path={authenticatedIndexPaths} shouldRedirect={false}>
                <Redirect to={config.options.pages.defaultPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute shouldRedirect={false}>
                <NotFoundPage />
              </AuthenticatedRoute>
            </Switch>
          </>
        </Switch>
      </AppContainer>
    </Router>
  );
});
