// @ts-ignore
import Location from 'app-location';
import * as Yup from 'yup';

const id = Yup.string();
const campaignType = Yup.mixed().oneOf(['branding', 'listing']);

const locations = {
  signUp: new Location('/sign-up'),
  termsOfService: new Location('/terms-of-service'),
  privacyPolicy: new Location('/privacy-policy'),
  invitedSignUp: new Location('/invited-sign-up/:uid/:token'),
  accountActivation: new Location('/activate/:uid/:token', {
    uid: id,
    token: id,
  }),
  signInWithPassword: new Location('/sign-in', null, {
    dest: Yup.string(),
  }),
  forgotPassword: new Location('/forgot-password'),
  restorePassword: new Location('/restore-password/:uid/:token', {
    uid: id,
    token: id,
  }),

  index: new Location('/'),
  dashboard: new Location('/dashboard'),
  profile: new Location('/profile', null, {
    openModal: Yup.boolean(),
    platform: Yup.string(),
  }),

  frequentlyAskedQuestion: new Location('/frequently-asked-question', null, {
    section: Yup.string(),
  }),

  organization: {
    root: new Location('/organization'),
    setup: new Location('/organization/setup'),
    details: new Location('/organization/details'),
  },

  campaigns: {
    root: new Location('/campaigns'),
    list: new Location('/campaigns', null, { type: campaignType }),
    campaignReports: new Location('/campaigns/:id/reports', {
      id,
    }),
    newBuildingCampaign: new Location('/campaigns/new/building'),
    buildingCampaign: new Location(
      '/campaigns/:id/building',
      {
        id,
      },
      { showWarn: Yup.boolean() },
    ),
    newListingCampaign: new Location('/campaigns/new/listing'),
    listingCampaign: new Location(
      '/campaigns/:id/listing',
      {
        id,
      },
      { showWarn: Yup.boolean() },
    ),
    newOpenHouseCampaign: new Location('/campaigns/new/open-house'),
    openHouseCampaign: new Location(
      '/campaigns/:id/open-house',
      {
        id,
      },
      { showWarn: Yup.boolean() },
    ),
    newBrandingCampaign: new Location('/campaigns/new/branding'),
    brandingCampaign: new Location(
      '/campaigns/:id/branding',
      {
        id,
      },
      { showWarn: Yup.boolean() },
    ),
    abTesting: new Location('/campaigns/:id/ab-testing', {
      id,
    }),
  },

  buildings: {
    root: new Location('/buildings'),
    list: new Location('/buildings'),
    newBuilding: new Location('/buildings/new'),
    building: new Location('/buildings/:id', {
      id,
    }),
  },

  listings: {
    root: new Location('/listings'),
    list: new Location('/listings'),
    newListing: new Location('/listings/new'),
    listing: new Location('/listings/:id', {
      id,
    }),
  },

  reports: {
    root: new Location('/reports'),
    list: new Location('/reports'),
  },
  marketingDescription: new Location('/marketing-description'),
  creativeIntelligence: new Location('/creative-intelligence'),
  creativeManagement: new Location('/creative-management'),
};

export const unauthenticatedLocations = [locations.signInWithPassword.path];

const locationCriteria = '_';

const isLocationObject = (value: Record<string, any>): boolean =>
  Boolean(Object.keys(value).find((value: string) => value[0] === locationCriteria));

const toArrayFromLocationObject = (obj: Record<string, any>): any[] =>
  Object.values(obj).reduce((result, value) => {
    if (typeof value === 'object' && !isLocationObject(value)) {
      return [...result, ...toArrayFromLocationObject(value)];
    }

    return [...result, value];
  }, []);

export const locationsArray = toArrayFromLocationObject(locations);

export const allPathnames = locationsArray.map((location: any) => location.path);

export default locations;
