import { toGenerator } from 'mobx-state-tree';
import BaseModel from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromItemResponse, fromListResponse } from 'services/api';
import avatarPlaceholderImg from 'assets/images/avatar-placeholder.png';
import {
  deserializeOptions,
  deserializeOptionsEntity,
  deserializeOptionDetails,
  deserializeTagSelectOptions,
  deserializeCampaignTextOptions,
  deserializeKeywordGroups,
  deserializeOptionsDetailed,
  deserializeFacebookCustomAudienceOptions,
  deserializeOrganizationOptions,
  deserializeUserOptions,
} from 'utils/deserializers';
import {
  BuildingApi,
  CampaignApi,
  FacebookCustomAudienceOption,
  GeoTargetingValue,
  KeywordsGroupFormValue,
  ListingApi,
  Option,
  OptionDetailed,
  OptionWithOldMarker,
  SearchQuery,
  SupportedUserOptionApi,
  TagSelectOption,
} from 'types';

export const CommonStore = BaseModel.named('CommonStore')
  .props({})
  .actions((self) => ({
    getStateOptions: apiFlow<Option[]>(function* getStateOptions({ search }: SearchQuery) {
      const response = yield* toGenerator(self.api.getStatesOptions({ search }));
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getStateInitialOptions: apiFlow<Option[]>(function* getStateInitialOptions({ value }: Option) {
      const response = yield* toGenerator(self.api.getStateDetails({ id: value }));

      const { data } = fromItemResponse({
        response: response.data,
      });

      return data ? [deserializeOptionDetails(data)] : [];
    }),

    getCityOptions: apiFlow<Option[]>(function* getCityOptions({ search, state }: SearchQuery & { state: string }) {
      const response = yield* toGenerator(self.api.getCityOptions({ search, state }));
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getCityInitialOptions: apiFlow<Option[]>(function* getCityInitialOptions({ value }: Option) {
      const response = yield* toGenerator(self.api.getCityDetails({ id: value }));

      const { data } = fromItemResponse({
        response: response.data,
      });

      return data ? [deserializeOptionDetails(data)] : [];
    }),

    getBuildingPropertyTypeOptions: apiFlow<Option[]>(function* getBuildingPropertyTypeOptions() {
      const response = yield* toGenerator(self.api.getBuildingPropertyTypeOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getListingPropertyTypeOptions: apiFlow<Option[]>(function* getListingPropertyTypeOptions() {
      const response = yield* toGenerator(self.api.getListingPropertyTypeOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getApartmentParameterTypeOptions: apiFlow<Option[]>(function* getApartmentParameterTypeOptions() {
      const response = yield* toGenerator(self.api.getApartmentParameterOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getOrganizationOptions: apiFlow(function* getOrganizationOptions() {
      const response = yield* toGenerator(self.api.getOrganizations());

      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOrganizationOptions(data);
    }),

    getUsersByOrganizationOptions: apiFlow(function* getUsersByOrganizationOptions(id: SupportedUserOptionApi['id']) {
      const response = yield* toGenerator(self.api.getUsersByOrganization(id));

      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeUserOptions(data);
    }),

    getBuildingOptions: apiFlow<Option[]>(function* getBuildingOptions() {
      const response = yield* toGenerator(self.api.getBuildingOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptionsEntity(data);
    }),

    getBuildingAmenityOptions: apiFlow<TagSelectOption[]>(function* getBuildingAmenityOptions() {
      const response = yield* toGenerator(self.api.getBuildingAmenityOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeTagSelectOptions(data);
    }),

    getYardiConnectionStatus: apiFlow<any>(function* getYardiConnectionStatus() {
      const response = yield* toGenerator(self.api.getYardiConnectionStatus());
      return response?.data?.connected;
    }),

    getBuildingCompetitorsOptions: apiFlow<TagSelectOption[]>(function* getBuildingCompetitorsOptions() {
      const response = yield* toGenerator(self.api.getBuildingCompetitorOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeTagSelectOptions(data);
    }),

    getMasstransitOptions: apiFlow<TagSelectOption[]>(function* getMasstransitOptions() {
      const response = yield* toGenerator(self.api.getMasstransitOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeTagSelectOptions(data);
    }),

    getListingAmenityOptions: apiFlow<TagSelectOption[]>(function* getListingAmenityOptions() {
      const response = yield* toGenerator(self.api.getListingAmenityOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeTagSelectOptions(data);
    }),

    getListingTypeOptions: apiFlow<Option[]>(function* getListingTypeOptions() {
      const response = yield* toGenerator(self.api.getListingTypeOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeTagSelectOptions(data);
    }),

    getListingOptions: apiFlow<Option[]>(function* getListingOptions() {
      const response = yield* toGenerator(self.api.getListingOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptionsEntity(data);
    }),

    getAdBuildingDescriptionRegenerateOptions: apiFlow<OptionWithOldMarker[]>(
      function* getAdBuildingDescriptionRegenerateOptions({
        id,
        platform,
        selectedGroup,
        use_case_group,
        asset_number,
      }: {
        id: BuildingApi['id'];
        platform: string;
        selectedGroup: string;
        use_case_group: string;
        asset_number: string;
      }) {
        const response = yield* toGenerator(
          self.api.getAdBuildingDescriptionRegenerateOptions({
            id,
            platform,
            // @ts-ignore
            use_case_group,
            asset_number,
            // @ts-ignore
            selectedGroup,
          }),
        );
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getAdBuildingTitleRegenerateOptions: apiFlow<OptionWithOldMarker[]>(function* getAdBuildingTitleRegenerateOptions({
      id,
      platform,
      selectedGroup,
      use_case_group,
      asset_number,
    }: {
      id: BuildingApi['id'];
      platform: string;
      selectedGroup: string;
      use_case_group: string;
      asset_number: string;
    }) {
      const response = yield* toGenerator(
        self.api.getAdBuildingTitleRegenerateOptions({
          id,
          platform,
          // @ts-ignore
          use_case_group,
          asset_number,
          // @ts-ignore
          selectedGroup,
        }),
      );
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeCampaignTextOptions(data);
    }),

    getBuildingAdDescriptionSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getBuildingAdDescriptionSuggestionOptions({ id }: { id: BuildingApi['id'] }) {
        const response = yield* toGenerator(self.api.getBuildingAdDescriptionSuggestionOptions({ id }));
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getBuildingAdTitleSuggestionOptions: apiFlow<OptionWithOldMarker[]>(function* getBuildingAdTitleSuggestionOptions({
      id,
    }: {
      id: BuildingApi['id'];
    }) {
      const response = yield* toGenerator(self.api.getBuildingAdTitleSuggestionOptions({ id }));
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeCampaignTextOptions(data);
    }),

    getListingAdTitleSuggestionOptions: apiFlow<OptionWithOldMarker[]>(function* getListingAdTitleSuggestionOptions({
      id,
    }: {
      id: ListingApi['id'];
    }) {
      const response = yield* toGenerator(self.api.getListingAdTitleSuggestionOptions({ id }));
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeCampaignTextOptions(data);
    }),

    getListingAdDescriptionSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getListingAdDescriptionSuggestionOptions({ id }: { id: ListingApi['id'] }) {
        const response = yield* toGenerator(self.api.getListingAdDescriptionSuggestionOptions({ id }));
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getAdListingDescriptionRegenerateOptions: apiFlow<OptionWithOldMarker[]>(
      function* getAdListingDescriptionRegenerateOptions({
        id,
        platform,
        use_case_group,
        asset_number,
      }: {
        id: BuildingApi['id'];
        platform: string;
        use_case_group: string;
        asset_number: number;
      }) {
        const response = yield* toGenerator(
          self.api.getAdListingDescriptionRegenerateOptions({
            id,
            platform,
            // @ts-ignore
            use_case_group,
            asset_number,
          }),
        );
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getAdListingTitleRegenerateOptions: apiFlow<OptionWithOldMarker[]>(function* getAdListingTitleRegenerateOptions({
      id,
      platform,
      selectedGroup,
      use_case_group,
      asset_number,
    }: {
      id: BuildingApi['id'];
      platform: string;
      selectedGroup: string;
      use_case_group: string;
      asset_number: number;
    }) {
      const response = yield* toGenerator(
        self.api.getAdListingTitleRegenerateOptions({
          id,
          platform,
          // @ts-ignore
          use_case_group,
          asset_number,
          // @ts-ignore
          selectedGroup,
        }),
      );
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeCampaignTextOptions(data);
    }),

    getAdBrandingDescriptionRegenerateOptions: apiFlow<OptionWithOldMarker[]>(
      function* getAdBrandingDescriptionRegenerateOptions({ typeId, platform }: { typeId: string; platform: string }) {
        const response = yield* toGenerator(
          self.api.getAdBrandingDescriptionRegenerateOptions({
            typeId,
            platform,
          }),
        );
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getAdBrandingTitleRegenerateOptions: apiFlow<OptionWithOldMarker[]>(function* getAdBrandingTitleRegenerateOptions({
      typeId,
      platform,
    }: {
      typeId: string;
      platform: string;
    }) {
      const response = yield* toGenerator(
        self.api.getAdBrandingTitleRegenerateOptions({
          typeId,
          platform,
        }),
      );
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeCampaignTextOptions(data);
    }),

    getBrandingUserAdTitleSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getBrandingUserAdTitleSuggestionOptions() {
        const response = yield* toGenerator(self.api.getBrandingUserAdTitleSuggestionOptions());
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getBrandingUserAdDescriptionSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getBrandingUserAdDescriptionSuggestionOptions() {
        const response = yield* toGenerator(self.api.getBrandingUserAdDescriptionSuggestionOptions());
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getBrandingOrganizationAdTitleSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getBrandingOrganizationAdTitleSuggestionOptions() {
        const response = yield* toGenerator(self.api.getBrandingOrganizationAdTitleSuggestionOptions());
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getBrandingOrganizationAdDescriptionSuggestionOptions: apiFlow<OptionWithOldMarker[]>(
      function* getBrandingOrganizationAdDescriptionSuggestionOptions() {
        const response = yield* toGenerator(self.api.getBrandingOrganizationAdDescriptionSuggestionOptions());
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeCampaignTextOptions(data);
      },
    ),

    getKeywordOptions: apiFlow<KeywordsGroupFormValue[]>(function* getKeywordOptions({
      campaign_id,
      campaign_kind,
      target_kind,
      target_id,
    }: {
      campaign_id?: CampaignApi['id'];
      campaign_kind: CampaignApi['kind'];
      target_kind?: CampaignApi['target_kind'];
      target_id: CampaignApi['target'];
    }) {
      const response = yield* toGenerator(
        self.api.getCampaignKeywordOptions({
          campaign_id,
          campaign_kind,
          target_kind,
          target_id,
        }),
      );
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeKeywordGroups(data);
    }),

    getOrganizationKindOptions: apiFlow<Option[]>(function* getOrganizationKindOptions() {
      const response = yield* toGenerator(self.api.getOrganizationKindOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getTypesOfPropertiesOptions: apiFlow<Option[]>(function* getTypesOfPropertiesOptions() {
      const response = yield* toGenerator(self.api.getTypesOfPropertiesOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getFacebookPageOptions: apiFlow<OptionDetailed[]>(function* getFacebookPageOptions() {
      const response = yield* toGenerator(self.api.getFacebookPageOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptionsDetailed(data);
    }),

    getInstagramAccountOptions: apiFlow<OptionDetailed[]>(function* getInstagramAccountOptions() {
      const response = yield* toGenerator(self.api.getInstagramAccountOptions());
      const { data } = fromListResponse({
        response: response.data,
      });
      const options = deserializeOptionsDetailed(data);

      return options.map(({ picture, ...option }) => ({
        picture: picture ?? avatarPlaceholderImg,
        ...option,
      }));
    }),

    getFacebookPixelOptions: apiFlow<OptionDetailed[]>(function* getFacebookPixelOptions() {
      const response = yield* toGenerator(self.api.getFacebookPixelOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptionsDetailed(data);
    }),

    getFacebookCustomAudienceOptions: apiFlow<FacebookCustomAudienceOption[]>(
      function* getFacebookCustomAudienceOptions() {
        const response = yield* toGenerator(self.api.getFacebookCustomAudienceOptions());
        const { data } = fromListResponse({
          response: response.data,
        });

        return deserializeFacebookCustomAudienceOptions(data);
      },
    ),

    getGoogleAccountOptions: apiFlow<Option[]>(function* getGoogleAccountOptions() {
      const response = yield* toGenerator(self.api.getGoogleAccountOptions());
      const { data } = fromListResponse({
        response: response.data,
      });

      return deserializeOptions(data);
    }),

    getGeoTarget: apiFlow<GeoTargetingValue>(function* getGeoTarget({
      stateId,
      cityId,
    }: {
      stateId: string;
      cityId: string;
    }) {
      const [stateResponse, cityResponse] = yield* toGenerator(
        Promise.all([self.api.getStateDetails({ id: stateId }), self.api.getCityDetails({ id: cityId })]),
      );

      const { data: stateData } = fromItemResponse({
        response: stateResponse.data,
      });
      const { data: cityData } = fromItemResponse({
        response: cityResponse.data,
      });

      return {
        state: {
          value: String(stateData.id),
          label: stateData.name,
          abbr: stateData.postal_abreviation,
        },
        city: {
          value: String(cityData.id),
          label: cityData.name,
        },
      };
    }),

    getApiVersion: apiFlow<{ apiVersion: string }>(function* getApiVersion() {
      const response = yield* toGenerator(self.api.getVersion());

      const { data } = fromItemResponse({
        response: response.data,
      });

      return { apiVersion: data.api_version };
    }),
  }));
